body {
  min-height: 100%;
  background-color: #fafafa;

  .login-container {
    width: 420px;
    height: 385px;
    margin: auto;
    border-radius: 72px;
    background-color: #fff;
    padding: 60px;
  }

  .login-header {
    width: 63px;
    height: 33px;
    margin: 0 119px 0 1px;
    
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #707070;
  }

  .login-description {
    width: 300px;
    height: 20px;
    margin: 12px;

    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #707070;
  }

  .login-input {
    width: 300px;
    height: 48px;
    margin: 12px 1px 8px 0;
    padding: 14px 14px 14px 20px;
    border-radius: 24px;
    box-shadow: inset 0 0 0 2px #4c84ff, 0 0 0 4px #e4edff;
    background-color: #fff;
  }
  
  .login-input-text {
    width: 131px;
    height: 20px;
    
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #4c84ff;
  }

  

  .forgot-password {
    width: 117px;
    height: 20px;
    margin: 8px 1px 20px 11px;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #4c84ff;
    text-align: center;
  }
  
  .login-submit-btn {
    width: 94px;
    height: 48px;
    margin: 20px;
    padding: 12px 16px;
    border-radius: 14px;
    background-color: #4c84ff;
    float: right;
    text-align: center;
    cursor: pointer;
  }

  .login-submit-btn-text {
    width: 62px;
    height: 24px;
    font-size: 18px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.75px;
    color: #fff;
  }
  
}

