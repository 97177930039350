.navigation {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  .logo-container {
    height: 100%;
    width: 70px;
    padding: 25px;
  }

  .nav-links-container {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .nav-link {
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}

.headerLogo{
  
  text-align: center;
  margin: 45px 50px;
  width: 100px;

  img{
    width: 100px;
  }
  
}

.nav-menu {

  height: 48px;
  cursor: pointer;
  padding:10px;
  

  img {
    display: inline-block;
    width: 28px;
    height: 28.2px;
    margin: 0 12px 0 0;
  }
  h4 {
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #707070;
    display: inline-block;
    border-radius: 25px;
  }
}