.main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.display-hidden{
  display: none !important;
}
.display-show{
  display: flex !important;
}


/*THIS IS PLACEHOLDER STYLING FOR FRONT-END CODING*/
#placeholder-nav {
    height: 100vh;
    margin: 0px;
    padding: 0px;
    background-color: #4D85FF;
  }
  @media screen and (max-width: 768px) {
    #placeholder-nav {
      width: 104px;
    }
  }
  @media screen and (min-width: 769px) {
    #placeholder-nav {
      width: 224px;
    }
  }
  
  
  
  /*THIS IS PLACEHOLDER STYLING FOR FRONT-END CODING*/
  #placeholder-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  @media screen and (max-width: 768px) {
    #placeholder-wrapper {
      max-width: calc(100% - 104px);
    }
  }
  @media screen and (min-width: 769px) {
    #placeholder-wrapper {
      max-width: calc(100% - 224px);
    }
  }
  /*----------------------------------------------- GENERAL/FRAMEWORK (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- HEADER-CARE -----------------------------------------------*/
  #header-care {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 48px;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  
  
  
  /*TOP*/
  #header-care .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 44px;
    margin-bottom: 84px;
  }
  
  #header-care .top .buttons-left a {
    text-decoration: none;
  }
  
  #header-care .top .buttons-left img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
  }
  
  #header-care .top .buttons-left img:hover {
    background-color: #E5EDFF;
  }
  
  
  
  /*BOTTOM*/
  #header-care .bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 48px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  
  
  /*H2*/
  #header-care > h2 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  /*H5*/
  #header-care > h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
    margin-top: -4px;
    margin-bottom: 12px;
  }
  
  
  
  /*BUTTONS*/
  #header-care .bottom .buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    #header-care .bottom .buttons {
      /*INCREASE WIDTH BY 40X PX IF ADD ANOTHER BUTTON*/
      max-width: 360px;
    }  
  }
  
  
  
  /*BUTTON*/
  #header-care .bottom .buttons .button {
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    height: 40px;
    align-items: center;
    text-decoration: none;
    position: relative;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  /*BUTTON H5*/
  #header-care .bottom .buttons .button h5 {
    font-weight: 600;
    color: #4C84FF;
    padding-left: 12px;
    padding-right: 4px;
    display: none;
  }
  
  /*BUTTON IMG*/
  #header-care .bottom .buttons .button img {
    height: 32px;
    width: 32px;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 50%;
    display: block;
    background-color: white;
  }
  
  /*BADGE*/
  #header-care .bottom .buttons .button .badge {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color: #F5A623;
  }
  
  /*BADGE H6*/
  #header-care .bottom .buttons .button .badge h6 {
    font-weight: 700;
    color: white;
    letter-spacing: -.5px;
  }
  
  
  
  /*ACTIVE BUTTON*/
  #header-care .bottom .buttons .active-button {
    background-color: #4C84FF;
  }
  
  #header-care .bottom .buttons .active-button h5 {
    display: block;
    color: white;
  }
  
  #header-care .bottom .buttons .active-button img {
    background-color: #F2F6FF;
  }
  
  
  
  /*NON-ACTIVE BUTTON*/
  #header-care .bottom .buttons .non-active-button:hover {
    box-shadow: 0px 0px 0px 2px #4C84FF inset;
  }
  
  #header-care .bottom .buttons .non-active-button:hover img {
    background-color: #F2F6FF;
    box-shadow: 0px 0px 0px 2px white inset;
  }
  
  #header-care .bottom .buttons .non-active-button:hover .tooltip {
    display: flex;
  }
  
  
  
  /*TOOLTIP*/
  #header-care .tooltip h6 {
    width: 100%;
    font-weight: 600;
    text-align: center;
    color: #4C84FF;
  }
  
  #header-care .tooltip {
    display: none;
    position: absolute;
    top: -40px;
    left: -20px;
    margin: auto;
    width: 60px;
    background-color: white;
    height: 40px;
    border-radius: 20px;
    align-items: center;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
    z-index: 2;
  }
  
  #header-care .bottom .buttons .non-active-button .tooltip:after, #header-care .bottom .buttons .non-active-button .tooltip:before {
    position: absolute;
    width: 0px; 
    height: 0px;
    bottom: -12px;
    right: 0px;
    left: 0px;
    margin: auto;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid white;
    content: " ";
  }
  /*----------------------------------------------- HEADER-CARE (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- PROFILE (GENERIC) -----------------------------------------------*/
  /*CARDS*/
  #profile {
    width: calc(100% - 20px); 
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /*CARDS*/
  #configurations {
    width: calc(100% - 20px); 
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /*CARDS*/
  #comorbidity-view {
    width: calc(100% - 20px); 
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  /*CARDS*/
  #client-journey-tab {
    width: calc(100% - 20px); 
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  
  /*CARD*/
  #profile [class*="card"] {
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
  }
  
  /*CARD*/
  #configurations [class*="card"] {
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
  }
  
  /*CARD*/
  #comorbidity-view [class*="card"] {
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
  }
  
  /*CARD*/
  #client-journey-tab [class*="card"] {
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
  }
  
  
  
  
  #profile [class*="card"]:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  #client-journey-tab [class*="card"]:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  #configurations [class*="card"]:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  #comorbidity-view [class*="card"]:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  /*CARD-FULL*/
  #profile [class*="card-full"] {
    width: 100%;
  }
  
  /*CARD-FULL*/
  #client-journey-tab [class*="card-full"] {
    width: 100%;
  }
  
  /*CARD-FULL*/
  #configurations [class*="card-full"] {
    width: 100%;
  }
  
  /*CARD-FULL*/
  #comorbidity-view [class*="card-full"] {
    width: 100%;
  }
  
  /*CARD-THIRD*/
  #profile [class*="card-third"] {
    height: 340px;
    overflow: hidden;
  }
  /*CARD-THIRD*/
  #client-journey-tab [class*="card-third"] {
    height: 340px;
    overflow: hidden;
  }
  /*CARD-THIRD*/
  #configurations [class*="card-third"] {
    height: 340px;
    overflow: hidden;
  }
  
  /*CARD-THIRD*/
  #comorbidity-view [class*="card-third"] {
    height: 340px;
    overflow: hidden;
  }
  @media screen and (min-width: 1144px) {
    #profile [class*="card-third"] {
      width: 100%;
      max-width: 265px;
    }
  }
  @media screen and (min-width: 1144px) {
    #client-journey-tab [class*="card-third"] {
      width: 100%;
      max-width: 265px;
    }
  }
  @media screen and (min-width: 1144px) {
    #configurations [class*="card-third"] {
      width: 100%;
      max-width: 265px;
    }
  }
  
  @media screen and (min-width: 1144px) {
    #comorbidity-view [class*="card-third"] {
      width: 100%;
      max-width: 265px;
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    #profile [class*="card-third"] {
      width: calc(50% - 20px);
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    #client-journey-tab [class*="card-third"] {
      width: calc(50% - 20px);
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    #configurations [class*="card-third"] {
      width: calc(50% - 20px);
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    #comorbidity-view [class*="card-third"] {
      width: calc(50% - 20px);
    }
  }
  @media screen and (max-width: 843px) {
    #profile [class*="card-third"] {
      width: calc(100% - 20px);
    }
  }
  @media screen and (max-width: 843px) {
    #client-journey-tab [class*="card-third"] {
      width: calc(100% - 20px);
    }
  }
  
  @media screen and (max-width: 843px) {
    #configurations [class*="card-third"] {
      width: calc(100% - 20px);
    }
  }
  @media screen and (max-width: 843px) {
    #comorbidity-view [class*="card-third"] {
      width: calc(100% - 20px);
    }
  }
  
  
  
  /*CARD-HEADER*/
  #profile [class*="card"] .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #F5F6F8;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  
  /*CARD-HEADER*/
  #client-journey-tab [class*="card"] .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #F5F6F8;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  
  /*CARD-HEADER*/
  #configurations [class*="card"] .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #F5F6F8;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  
  /*CARD-HEADER*/
  #comorbidity-view [class*="card"] .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #F5F6F8;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  
  #profile [class*="card"] .header h4 {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 12px;
    text-align: center;
  }
  
  #client-journey-tab [class*="card"] .header h4 {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 12px;
    text-align: center;
  }
  
  #configurations [class*="card"] .header h4 {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 12px;
    text-align: center;
  }
  
  #comorbidity-view [class*="card"] .header h4 {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 12px;
    text-align: center;
  }
  
  
  
  /*CARD-THIRD-BODY*/
  #profile [class*="card-third"] .body {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(100% - 24px);
    padding-right: 12px;
    padding-left: 12px;
    height: 260px;
    margin-top: 10px;
    overflow: scroll;
  /*  background-color: magenta;*/
  }
  
  /*CARD-THIRD-BODY*/
  #client-journey-tab [class*="card-third"] .body {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(100% - 24px);
    padding-right: 12px;
    padding-left: 12px;
    height: 260px;
    margin-top: 10px;
    overflow: scroll;
  /*  background-color: magenta;*/
  }
  
  /*CARD-THIRD-BODY*/
  #configurations [class*="card-third"] .body {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(100% - 24px);
    padding-right: 12px;
    padding-left: 12px;
    height: 260px;
    margin-top: 10px;
    overflow: scroll;
  /*  background-color: magenta;*/
  }
  
  /*CARD-THIRD-BODY*/
  #comorbidity-view [class*="card-third"] .body {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(100% - 24px);
    padding-right: 12px;
    padding-left: 12px;
    height: 260px;
    margin-top: 10px;
    overflow: scroll;
  /*  background-color: magenta;*/
  }
  
  
  
  /*MODULE*/
  #profile [class*="card-third"] .body .module {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
  /*  background-color: yellow;*/
  }
  
  /*MODULE*/
  #client-journey-tab [class*="card-third"] .body .module {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
  /*  background-color: yellow;*/
  }
  
  /*MODULE*/
  #configurations [class*="card-third"] .body .module {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
  /*  background-color: yellow;*/
  }
  
  /*MODULE*/
  #comorbidity-view [class*="card-third"] .body .module {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
  /*  background-color: yellow;*/
  }
  
  
  #profile [class*="card-third"] .body .module:not(:last-child) {
    border-bottom: 2px solid #f8f9fc;
  }
  
  #client-journey-tab [class*="card-third"] .body .module:not(:last-child) {
    border-bottom: 2px solid #f8f9fc;
  }
  
  #configurations [class*="card-third"] .body .module:not(:last-child) {
    border-bottom: 2px solid #f8f9fc;
  }
  
  #comorbidity-view [class*="card-third"] .body .module:not(:last-child) {
    border-bottom: 2px solid #f8f9fc;
  }
  
  
  /*MODULE-TOP*/
  #profile [class*="card-third"] .body .module .module-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  /*  background-color: orange;*/
  }
  
  /*MODULE-TOP*/
  #client-journey-tab [class*="card-third"] .body .module .module-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  /*  background-color: orange;*/
  }
  /*MODULE-TOP*/
  #configurations [class*="card-third"] .body .module .module-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  /*  background-color: orange;*/
  }
  
  /*MODULE-TOP*/
  #comorbidity-view [class*="card-third"] .body .module .module-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  /*  background-color: orange;*/
  }
  
  #profile [class*="card-third"] .body .module .module-top .module-icon {
    width: 32px;
    height: 32px;
  }
  
  #client-journey-tab [class*="card-third"] .body .module .module-top .module-icon {
    width: 32px;
    height: 32px;
  }
  #configurations [class*="card-third"] .body .module .module-top .module-icon {
    width: 32px;
    height: 32px;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top .module-icon {
    width: 32px;
    height: 32px;
  }
  
  #profile [class*="card-third"] .body .module .module-top div {
    width: calc(100% - 60px);
    margin-right: 6px;
    margin-left: 8px;
  }
  #client-journey-tab [class*="card-third"] .body .module .module-top div {
    width: calc(100% - 60px);
    margin-right: 6px;
    margin-left: 8px;
  }
  
  #configurations [class*="card-third"] .body .module .module-top div {
    width: calc(100% - 60px);
    margin-right: 6px;
    margin-left: 8px;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top div {
    width: calc(100% - 60px);
    margin-right: 6px;
    margin-left: 8px;
  }
  
  #profile [class*="card-third"] .body .module .module-top div h5 {
    width: 100%;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  /*  background-color: yellow;*/
  }
  
  #client-journey-tab [class*="card-third"] .body .module .module-top div h5 {
    width: 100%;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  /*  background-color: yellow;*/
  }
  
  
  #configurations [class*="card-third"] .body .module .module-top div h5 {
    width: 100%;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  /*  background-color: yellow;*/
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top div h5 {
    width: 100%;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  /*  background-color: yellow;*/
  }
  
  
  #profile [class*="card-third"] .body .module .module-top div h5:hover {
    /* overflow: visible; */
    white-space:normal;
  }
  
  #client-journey-tab [class*="card-third"] .body .module .module-top div h5:hover {
    /* overflow: visible; */
    white-space:normal;
  }
  
  #configurations [class*="card-third"] .body .module .module-top div h5:hover {
    /* overflow: visible; */
    white-space:normal;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top div h5:hover {
    /* overflow: visible; */
    white-space:normal;
  }
  
  #profile [class*="card-third"] .body .module .module-top div h6 {
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  #client-journey-tab [class*="card-third"] .body .module .module-top div h6 {
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  #configurations [class*="card-third"] .body .module .module-top div h6 {
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top div h6 {
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  
  #profile [class*="card-third"] .body .module .module-top div h6:hover {
    /* overflow: visible; */
    white-space:normal;
  }
  
  #client-journey-tab [class*="card-third"] .body .module .module-top div h6:hover {
    /* overflow: visible; */
    white-space:normal;
  }
  
  
  #configurations [class*="card-third"] .body .module .module-top div h6:hover {
    /* overflow: visible; */
    white-space:normal;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top div h6:hover {
    /* overflow: visible; */
    white-space:normal;
  }
  
  #configurations [class*="card-third"] .body .module .module-top [class*="module-status"] {
    width: 20px;
    height: 20px;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top [class*="module-status"] {
    width: 20px;
    height: 20px;
  }
  
  #profile [class*="card-third"] .body .module .module-top [class*="module-status"] {
    width: 20px;
    height: 20px;
  }
  #client-journey-tab [class*="card-third"] .body .module .module-top [class*="module-status"] {
    width: 20px;
    height: 20px;
  }
  
  #configurations [class*="card-third"] .body .module .module-top [class*="module-status"] {
    width: 20px;
    height: 20px;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top [class*="module-status"] {
    width: 20px;
    height: 20px;
  }
  
  
  #profile [class*="card-third"] .body .module .module-top .module-status-edit {
    border-radius: 10px;
  }
  
  #client-journey-tab [class*="card-third"] .body .module .module-top .module-status-edit {
    border-radius: 10px;
  }
  
  #configurations [class*="card-third"] .body .module .module-top .module-status-edit {
    border-radius: 10px;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top .module-status-edit {
    border-radius: 10px;
  }
  
  
  #profile [class*="card-third"] .body .module .module-top .module-status-edit:hover {
    background-color: #E5EDFF;
    cursor: pointer;
  }
  
  #client-journey-tab [class*="card-third"] .body .module .module-top .module-status-edit:hover {
    background-color: #E5EDFF;
    cursor: pointer;
  }
  
  #configurations [class*="card-third"] .body .module .module-top .module-status-edit:hover {
    background-color: #E5EDFF;
    cursor: pointer;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-top .module-status-edit:hover {
    background-color: #E5EDFF;
    cursor: pointer;
  }
  
  
  
  /*MODULE-BOTTOM*/
  #profile [class*="card-third"] .body .module .module-bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
  }
  
  /*MODULE-BOTTOM*/
  #client-journey-tab [class*="card-third"] .body .module .module-bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
  }
  
  /*MODULE-BOTTOM*/
  #configurations [class*="card-third"] .body .module .module-bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
  }
  
  /*MODULE-BOTTOM*/
  #comorbidity-view [class*="card-third"] .body .module .module-bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
  }
  
  #profile [class*="card-third"] .body .module .module-bottom h6 {
    width: 100%;
    padding-left: 40px;
  }
  
  #client-journey-tab [class*="card-third"] .body .module .module-bottom h6 {
    width: 100%;
    padding-left: 40px;
  }
  
  #configurations [class*="card-third"] .body .module .module-bottom h6 {
    width: 100%;
    padding-left: 40px;
  }
  
  #comorbidity-view [class*="card-third"] .body .module .module-bottom h6 {
    width: 100%;
    padding-left: 40px;
  }
  
  
  
  /*ADD*/
  #profile [class*="card-third"] .body .add {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    bottom: 4px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #E4EDFF;
  }
  
  /*ADD*/
  #client-journey-tab [class*="card-third"] .body .add {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    bottom: 4px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #E4EDFF;
  }
  
  /*ADD*/
  #configurations [class*="card-third"] .body .add {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    bottom: 4px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #E4EDFF;
  }
  
  /*ADD*/
  #comorbidity-view [class*="card-third"] .body .add {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    bottom: 4px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #E4EDFF;
  }
  
  
  #profile [class*="card-third"] .body .add:hover {
    background-color: #C9DAFF;
  }
  
  #client-journey-tab [class*="card-third"] .body .add:hover {
    background-color: #C9DAFF;
  }
  
  #configurations [class*="card-third"] .body .add:hover {
    background-color: #C9DAFF;
  }
  
  #comorbidity-view [class*="card-third"] .body .add:hover {
    background-color: #C9DAFF;
  }
  /*----------------------------------------------- PROFILE (GENERIC) (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- PROFILE (UNIQE-WEIGHT) -----------------------------------------------*/
  /*CARD*/
  @media screen and (min-width: 961px) {
    #profile .card-full-weight {
      height: 530px;
    }
  }
  
  @media screen and (min-width: 961px) {
    #client-journey-tab .card-full-weight {
      height: 530px;
    }
  }
  @media screen and (min-width: 961px) {
    #configurations .card-full-weight {
      height: 530px;
    }
  }
  
  /*HEADER-OPTIONS*/
  #profile .card-full-weight .header-options {
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  /*HEADER-OPTIONS*/
  #client-journey-tab .card-full-weight .header-options {
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  /*HEADER-OPTIONS*/
  #configurations .card-full-weight .header-options {
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  
  @media screen and (min-width: 961px) {
    #profile .card-full-weight .header-options {
      justify-content: space-between;
    }
  }
  @media screen and (min-width: 961px) {
    #client-journey-tab .card-full-weight .header-options {
      justify-content: space-between;
    }
  }
  
  @media screen and (min-width: 961px) {
    #configurations .card-full-weight .header-options {
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 960px) {
    #profile .card-full-weight .header-options {
      justify-content: center;
    }
  }
  
  @media screen and (max-width: 960px) {
    #client-journey-tab .card-full-weight .header-options {
      justify-content: center;
    }
  }
  @media screen and (max-width: 960px) {
    #configurations .card-full-weight .header-options {
      justify-content: center;
    }
  }
  
  
  
  /*----------HEADER-OPTIONS LEFT----------*/
  /*PLACEHOLDER-GRAPH*/
  @media screen and (min-width: 961px) {
    #profile .card-full-weight .header-options .placeholder-graph {
      width: 196px;
    }
  }
  
  /*PLACEHOLDER-GRAPH*/
  @media screen and (min-width: 961px) {
    #client-journey-tab .card-full-weight .header-options .placeholder-graph {
      width: 196px;
    }
  }
  
  @media screen and (min-width: 961px) {
    #configurations .card-full-weight .header-options .placeholder-graph {
      width: 196px;
    }
  }
  
  
  @media screen and (max-width: 960px) {
    #profile .card-full-weight .header-options .placeholder-graph {
      display: none;
    }
  }
  
  @media screen and (max-width: 960px) {
    #client-journey-tab .card-full-weight .header-options .placeholder-graph {
      display: none;
    }
  }
  
  @media screen and (max-width: 960px) {
    #configurations .card-full-weight .header-options .placeholder-graph {
      display: none;
    }
  }
  
  /*PLACEHOLDER-TABLE*/
  @media screen and (min-width: 961px) {
    #profile .card-full-weight .header-options .placeholder-table {
      width: 196px;
    }
  }
  
  /*PLACEHOLDER-TABLE*/
  @media screen and (min-width: 961px) {
    #client-journey-tab .card-full-weight .header-options .placeholder-table {
      width: 196px;
    }
  }
  
  /*PLACEHOLDER-TABLE*/
  @media screen and (min-width: 961px) {
    #configurations .card-full-weight .header-options .placeholder-table {
      width: 196px;
    }
  }
  
  @media screen and (max-width: 960px) {
    #profile .card-full-weight .header-options .placeholder-table {
      display: none;
    }
  }
  
  @media screen and (max-width: 960px) {
    #client-journey-tab .card-full-weight .header-options .placeholder-table {
      display: none;
    }
  }
  
  @media screen and (max-width: 960px) {
    #configurations .card-full-weight .header-options .placeholder-table {
      display: none;
    }
  }
  
  
  /*PLACEHOLDER-GOALS*/
  @media screen and (min-width: 961px) {
    #profile .card-full-weight .header-options .placeholder-goals {
      width: 128px;
    }
  }
  
  /*PLACEHOLDER-GOALS*/
  @media screen and (min-width: 961px) {
    #client-journey-tab .card-full-weight .header-options .placeholder-goals {
      width: 128px;
    }
  }
  
  /*PLACEHOLDER-GOALS*/
  @media screen and (min-width: 961px) {
    #configurations .card-full-weight .header-options .placeholder-goals {
      width: 128px;
    }
  }
  
  
  @media screen and (max-width: 960px) {
    #profile .card-full-weight .header-options .placeholder-goals {
      display: none;
    }
  }
  
  @media screen and (max-width: 960px) {
    #client-journey-tab .card-full-weight .header-options .placeholder-goals {
      display: none;
    }
  }
  
  @media screen and (max-width: 960px) {
    #configurations .card-full-weight .header-options .placeholder-goals {
      display: none;
    }
  }
  
  
  
  /*----------HEADER-OPTIONS CENTER----------*/
  #profile .card-full-weight .header-options .header-center {
    display: flex;
    justify-content: center;
  }
  
  #client-journey-tab .card-full-weight .header-options .header-center {
    display: flex;
    justify-content: center;
  }
  
  #configurations .card-full-weight .header-options .header-center {
    display: flex;
    justify-content: center;
  }
  
  
  
  @media screen and (max-width: 960px) {
    #profile .card-full-weight .header-options .header-center {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  
  @media screen and (max-width: 960px) {
    #client-journey-tab .card-full-weight .header-options .header-center {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  
  @media screen and (max-width: 960px) {
    #configurations .card-full-weight .header-options .header-center {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  
  
  
  /*HEADER-OPTIONS CENTER IMG*/
  #profile .card-full-weight .header-options .header-center img {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    cursor: pointer;
  }
  
  /*HEADER-OPTIONS CENTER IMG*/
  #client-journey-tab .card-full-weight .header-options .header-center img {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    cursor: pointer;
  }
  
  /*HEADER-OPTIONS CENTER IMG*/
  #configurations .card-full-weight .header-options .header-center img {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    cursor: pointer;
  }
  
  /*----------HEADER-OPTIONS RIGHT----------*/
  /*HEADER OPTIONS RANGE*/
  #profile .card-full-weight .header-options [class*="range"] {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  /*HEADER OPTIONS RANGE*/
  #client-journey-tab .card-full-weight .header-options [class*="range"] {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  /*HEADER OPTIONS RANGE*/
  #configurations .card-full-weight .header-options [class*="range"] {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  @media screen and (min-width: 401px) and (max-width: 960px) {
    #profile .card-full-weight .header-options [class*="range"] {
      width: 100%;
    }
  }
  
  @media screen and (min-width: 401px) and (max-width: 960px) {
    #client-journey-tab .card-full-weight .header-options [class*="range"] {
      width: 100%;
    }
  }
  
  @media screen and (min-width: 401px) and (max-width: 960px) {
    #configurations .card-full-weight .header-options [class*="range"] {
      width: 100%;
    }
  }
  
  
  @media screen and (max-width: 400px) {
    #profile .card-full-weight .header-options [class*="range"] {
      width: 108px;
      flex-wrap: wrap;
    }
  }
  
  @media screen and (max-width: 400px) {
    #client-journey-tab .card-full-weight .header-options [class*="range"] {
      width: 108px;
      flex-wrap: wrap;
    }
  }
  
  @media screen and (max-width: 400px) {
    #configurations .card-full-weight .header-options [class*="range"] {
      width: 108px;
      flex-wrap: wrap;
    }
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON*/
  #profile .card-full-weight .header-options [class*="range"] .range-button {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON*/
  #client-journey-tab .card-full-weight .header-options [class*="range"] .range-button {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON*/
  #configurations .card-full-weight .header-options [class*="range"] .range-button {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  
  @media screen and (min-width: 401px) {
    #profile .card-full-weight .header-options [class*="range"] .range-button {
      width: 60px;
    }
    #profile .card-full-weight .header-options [class*="range"] .range-button:not(:last-child) {
      margin-right: 8px;
    }
  }
  
  @media screen and (min-width: 401px) {
    #client-journey-tab .card-full-weight .header-options [class*="range"] .range-button {
      width: 60px;
    }
    #client-journey-tab .card-full-weight .header-options [class*="range"] .range-button:not(:last-child) {
      margin-right: 8px;
    }
  }
  
  @media screen and (min-width: 401px) {
    #configurations .card-full-weight .header-options [class*="range"] .range-button {
      width: 60px;
    }
    #configurations .card-full-weight .header-options [class*="range"] .range-button:not(:last-child) {
      margin-right: 8px;
    }
  }
  
  
  
  @media screen and (max-width: 400px) {
    #profile .card-full-weight .header-options [class*="range"] .range-button {
      width: 108px;
      margin-bottom: 8px;
    }
  }
  
  @media screen and (max-width: 400px) {
    #client-journey-tab .card-full-weight .header-options [class*="range"] .range-button {
      width: 108px;
      margin-bottom: 8px;
    }
  }
  
  @media screen and (max-width: 400px) {
    #configurations .card-full-weight .header-options [class*="range"] .range-button {
      width: 108px;
      margin-bottom: 8px;
    }
  }
  
  .range-button-active {
    background-color: #4C84FF;
  }
  .range-button-inactive {
    background-color: white;
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON H5*/
  #profile .card-full-weight .header-options [class*="range"] .range-button h6 {
    font-weight: 600;
  }
  
  #client-journey-tab .card-full-weight .header-options [class*="range"] .range-button h6 {
    font-weight: 600;
  }
  
  #configurations .card-full-weight .header-options [class*="range"] .range-button h6 {
    font-weight: 600;
  }
  .range-button-active h6 {
    color: white;
  }
  .range-button-inactive h6 {
    color: #4C84FF;
  }
  
  
  
  /*HEADER-OPTIONS GOAL-EDITOR*/
  #profile .card-full-weight .header-options .goal-editor {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  /*HEADER-OPTIONS GOAL-EDITOR*/
  #client-journey-tab .card-full-weight .header-options .goal-editor {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  /*HEADER-OPTIONS GOAL-EDITOR*/
  #configurations .card-full-weight .header-options .goal-editor {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  
  @media screen and (max-width: 960px) {
    #profile .card-full-weight .header-options .goal-editor {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 960px) {
    #client-journey-tab .card-full-weight .header-options .goal-editor {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 960px) {
    #configurations .card-full-weight .header-options .goal-editor {
      width: 100%;
    }
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON*/
  #profile .card-full-weight .header-options .goal-editor .goal-editor-button {
    width: 60px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  
  /*HEADER-OPTIONS RANGE-BUTTON*/
  #client-journey-tab .card-full-weight .header-options .goal-editor .goal-editor-button {
    width: 60px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON*/
  #configurations .card-full-weight .header-options .goal-editor .goal-editor-button {
    width: 60px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  
  #profile .card-full-weight .header-options .goal-editor .goal-editor-button:not(:last-child) {
    margin-right: 8px;
  }
  
  #client-journey-tab .card-full-weight .header-options .goal-editor .goal-editor-button:not(:last-child) {
    margin-right: 8px;
  }
  
  #configurations .card-full-weight .header-options .goal-editor .goal-editor-button:not(:last-child) {
    margin-right: 8px;
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON H5*/
  #profile .card-full-weight .header-options .goal-editor .goal-editor-button h6 {
    font-weight: 600;
    color: #4C84FF;
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON H5*/
  #client-journey-tab .card-full-weight .header-options .goal-editor .goal-editor-button h6 {
    font-weight: 600;
    color: #4C84FF;
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON H5*/
  #configurations .card-full-weight .header-options .goal-editor .goal-editor-button h6 {
    font-weight: 600;
    color: #4C84FF;
  }
  
  
  
  
  
  
  
  
  /*BODY*/
  #profile .card-full-weight .body {
    display: flex;
    justify-content: center;
  }
  
  #client-journey-tab .card-full-weight .body {
    display: flex;
    justify-content: center;
  }
  
  #configurations .card-full-weight .body {
    display: flex;
    justify-content: center;
  }
  
  @media screen and (min-width: 769px) {
    #profile .card-full-weight .body {
      height: calc(100% - 59px);
    }
  }
  
  @media screen and (min-width: 769px) {
    #client-journey-tab .card-full-weight .body {
      height: calc(100% - 59px);
    }
  }
  
  @media screen and (min-width: 769px) {
    #configurations .card-full-weight .body {
      height: calc(100% - 59px);
    }
  }
  
  /*----------TABLE----------*/
  /*TABLE RANGE*/
  #profile .card-full-weight .body .table-body {
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  /*TABLE RANGE*/
  #client-journey-tab .card-full-weight .body .table-body {
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  /*TABLE RANGE*/
  #configurations .card-full-weight .body .table-body {
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  
  /*TABLE CONTAINER*/
  #profile .card-full-weight .body .table-body .table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 274px;
    margin-top: 10px;
    margin-bottom: 40px;
    overflow-y: scroll;
  }
  
  /*TABLE CONTAINER*/
  #client-journey-tab .card-full-weight .body .table-body .table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 274px;
    margin-top: 10px;
    margin-bottom: 40px;
    overflow-y: scroll;
  }
  
  /*TABLE CONTAINER*/
  #configurations .card-full-weight .body .table-body .table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 274px;
    margin-top: 10px;
    margin-bottom: 40px;
    overflow-y: scroll;
  }
  
  /*TABLE*/
  #profile .card-full-weight .body .table-body .table-container table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    position: relative;
  }
  
  /*TABLE*/
  #client-journey-tab .card-full-weight .body .table-body .table-container table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    position: relative;
  }
  
  /*TABLE*/
  #configurations .card-full-weight .body .table-body .table-container table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    position: relative;
  }
  
  
  
  /*TR*/
  #profile .card-full-weight .body .table-body .table-container table tr {
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
  }
  
  
  /*TR*/
  #client-journey-tab .card-full-weight .body .table-body .table-container table tr {
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
  }
  
  /*TR*/
  #configurations .card-full-weight .body .table-body .table-container table tr {
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
  }
  
  
  
  #profile .card-full-weight .body .table-body .table-container table tr:hover:not(:first-child) {
    background-color: #F8F9FC;  
  }
  
  #client-journey-tab .card-full-weight .body .table-body .table-container table tr:hover:not(:first-child) {
    background-color: #F8F9FC;  
  }
  #configurations .card-full-weight .body .table-body .table-container table tr:hover:not(:first-child) {
    background-color: #F8F9FC;  
  }
  
  
  
  /*TH*/
  #profile .card-full-weight .body .table-body .table-container table th {
    text-align: center;
    padding-bottom: 10px;
    border: none;
    position: sticky;
    top: 0px;
    background-color: white;
  }
  
  /*TH*/
  #client-journey-tab .card-full-weight .body .table-body .table-container table th {
    text-align: center;
    padding-bottom: 10px;
    border: none;
    position: sticky;
    top: 0px;
    background-color: white;
  }
  
  /*TH*/
  #configurations .card-full-weight .body .table-body .table-container table th {
    text-align: center;
    padding-bottom: 10px;
    border: none;
    position: sticky;
    top: 0px;
    background-color: white;
  }
  
  
  /*TH H5*/
  #profile .card-full-weight .body .table-body .table-container table th h5 {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /*TH H5*/
  #client-journey-tab .card-full-weight .body .table-body .table-container table th h5 {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /*TH H5*/
  #configurations .card-full-weight .body .table-body .table-container table th h5 {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  
  /*TD*/
  #profile .card-full-weight .body .table-body .table-container table td {
    text-align: center;
    height: 24px;
  }
  
  /*TD*/
  #client-journey-tab .card-full-weight .body .table-body .table-container table td {
    text-align: center;
    height: 24px;
  }
  /*TD*/
  #configurations .card-full-weight .body .table-body .table-container table td {
    text-align: center;
    height: 24px;
  }
  
  
  /*TD H6*/
  #profile .card-full-weight .body .table-body .table-container table tr td h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /*TD H6*/
  #client-journey-tab .card-full-weight .body .table-body .table-container table tr td h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /*TD H6*/
  #configurations .card-full-weight .body .table-body .table-container table tr td h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  
  #profile .card-full-weight .body .table-body .table-container table td:last-of-type h6 {
    font-weight: 600;
  }
  
  #client-journey-tab .card-full-weight .body .table-body .table-container table td:last-of-type h6 {
    font-weight: 600;
  }
  
  #configurations .card-full-weight .body .table-body .table-container table td:last-of-type h6 {
    font-weight: 600;
  }
  
  
  /*ADD THESE CLASSES FOR WEEKLY SUMMARY IN JS*/
  .weight-table-weekly-summary-td {
    border-bottom: 2px solid #F5F6F8;
  }
  
  .weight-table-weekly-summary-h6 {
    font-weight: 600;
    margin-top: 25px;
    height: 25px;
  }
  
  
  
  /*MEDIA QUERIES FOR TABLE*/
  @media screen and (max-width: 600px) {
    #profile .card-full-weight .body .table-body .table-container table tr > *:nth-child(5) {
      display: none;
    }
  }
  
  @media screen and (max-width: 600px) {
    #client-journey-tab .card-full-weight .body .table-body .table-container table tr > *:nth-child(5) {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    #configurations .card-full-weight .body .table-body .table-container table tr > *:nth-child(5) {
      display: none;
    }
  }
  
  
  @media screen and (max-width: 500px) {
    #profile .card-full-weight .body .table-body .table-container table tr > *:nth-child(4) {
      display: none;
    }
  }
  
  @media screen and (max-width: 500px) {
    #client-journey-tab .card-full-weight .body .table-body .table-container table tr > *:nth-child(4) {
      display: none;
    }
  }
  
  @media screen and (max-width: 500px) {
    #configurations .card-full-weight .body .table-body .table-container table tr > *:nth-child(4) {
      display: none;
    }
  }
  
  
  
  
  @media screen and (max-width: 400px) {
    #profile .card-full-weight .body .table-body .table-container table tr > *:nth-child(3) {
      display: none;
    }
  }
  
  @media screen and (max-width: 400px) {
    #client-journey-tab .card-full-weight .body .table-body .table-container table tr > *:nth-child(3) {
      display: none;
    }
  }
  
  @media screen and (max-width: 400px) {
    #configurations .card-full-weight .body .table-body .table-container table tr > *:nth-child(3) {
      display: none;
    }
  }
  
  
  
  
  
  
  
  
  
  
  /*----------GRAPHS----------*/
  #profile .card-full-weight .graph-body {
    /*NOTE: 41PX B/C FOR SMALLER MEDIA QUERIES GRAPH REQUIRES 1 EXTRA PIXEL TO ADJUST;*/
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
  }
  
  #client-journey-tab .card-full-weight .graph-body {
    /*NOTE: 41PX B/C FOR SMALLER MEDIA QUERIES GRAPH REQUIRES 1 EXTRA PIXEL TO ADJUST;*/
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
  }
  
  #configurations .card-full-weight .graph-body {
    /*NOTE: 41PX B/C FOR SMALLER MEDIA QUERIES GRAPH REQUIRES 1 EXTRA PIXEL TO ADJUST;*/
    width: calc(100% - 41px);
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
  }
  
  
  /*CHARTJS-LINE*/
  #profile .card-full-weight .graph-body .weight-chart-container {
    width: 100%;
    height: 250px;
  }
  @media screen and (max-width: 960px) {
    #profile .card-full-weight .graph-body .weight-chart-container {
      margin-bottom: 12px;
    }
  }
  @media screen and (min-width: 961px) {
    #profile .card-full-weight .graph-body .weight-chart-container {
      margin-bottom: 4px;
    }
  }
  
  #client-journey-tab .card-full-weight .graph-body .weight-chart-container {
    width: 100%;
    height: 250px;
  }
  
  #configurations .card-full-weight .graph-body .weight-chart-container {
    width: 100%;
    height: 250px;
  }
  
  
  @media screen and (max-width: 960px) {
    #client-journey-tab .card-full-weight .graph-body .weight-chart-container {
      margin-bottom: 12px;
    }
  }
  @media screen and (min-width: 961px) {
    #client-journey-tab .card-full-weight .graph-body .weight-chart-container {
      margin-bottom: 4px;
    }
  }
  
  @media screen and (max-width: 960px) {
    #configurations .card-full-weight .graph-body .weight-chart-container {
      margin-bottom: 12px;
    }
  }
  @media screen and (min-width: 961px) {
    #configurations .card-full-weight .graph-body .weight-chart-container {
      margin-bottom: 4px;
    }
  }
  
  
  
  
  
  /*WEIGHT-STATS*/
  .weight-stats {
    width: calc(100% - 40px);
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px;
  }
  @media screen and (max-width: 960px) {
    .weight-stats {
      margin-bottom: 20px;
    }
  }
  
  /*WEIGHT-STATS MODULE*/
  .weight-stats .module {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 960px) {
    .weight-stats .module {
      width: 100%;
    }
  }
  @media screen and (max-width: 961px) {
    #profile .card-full-weight .graph-body .module:not(:last-child) {
      border-bottom: 2px solid #f8f9fc;
    }
  }
  
  @media screen and (max-width: 961px) {
    #client-journey-tab .card-full-weight .graph-body .module:not(:last-child) {
      border-bottom: 2px solid #f8f9fc;
    }
  }
  
  @media screen and (max-width: 961px) {
    #configurations .card-full-weight .graph-body .module:not(:last-child) {
      border-bottom: 2px solid #f8f9fc;
    }
  }
  
  /*WEIGHT-STATS MODULE-ICON*/
  .weight-stats .module [class*="module-icon"] {
    width: 32px;
    height: 32px;
  }
  .weight-stats .module:nth-of-type(3) [class*="module-icon"] {
    display: none;
  }
  
  /*WEIGHT-STATS MODULE-ICON DIV*/
  @media screen and (max-width: 960px) {
    .weight-stats .module div {
      width: calc(100% - 60px);
      margin-left: 8px;
    }
  }
  @media screen and (min-width: 961px) {
    .weight-stats .module div {
      margin-left: 6px;
    }
  }
  
  /*WEIGHT-STATS MODULE-ICON DIV H5*/
  .weight-stats .module div h5 {
    width: 100%;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .weight-stats .module div h5:hover {
    overflow: visible;
  }
  @media screen and (min-width: 961px) {
    .weight-stats .module div h5 {
      margin-top: -2px;
    }
  }
  
  /*WEIGHT-STATS MODULE-ICON DIV H6*/
  .weight-stats .module div h6 {
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .weight-stats .module div h6:hover {
    overflow: visible;
  }
  @media screen and (min-width: 961px) {
    .weight-stats .module div h6 {
      margin-top: -2px;
    }
  }
  
  
  
  
  
  
  
  
  
  /*----------GOALS----------*/
  #profile .card-full-weight .goals-body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  
  #client-journey-tab .card-full-weight .goals-body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  #configurations .card-full-weight .goals-body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  
  
  
  
  
  
  
  /*----------CONSTRUCTION----------*/
  #profile .card-full-weight .body .construction-weight {
    width: 220px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
  
  #profile .card-full-weight .body .construction-weight h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  #profile .card-full-weight .body .construction-weight h6 {
    width: 100%;
    text-align: center;
  }
  
  #profile .card-full-weight .body .construction-weight h6 a {
    text-decoration: none;
    font-weight: 600;
    color: #4C84FF;
  }
  
  #profile .card-full-weight .body .construction-weight img {
    width: 200px;
    text-align: center;
  }
  
  
  #client-journey-tab .card-full-weight .body .construction-weight {
    width: 220px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
  
  #configurations .card-full-weight .body .construction-weight {
    width: 220px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
  
  #client-journey-tab .card-full-weight .body .construction-weight h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  #configurations .card-full-weight .body .construction-weight h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  #client-journey-tab .card-full-weight .body .construction-weight h6 {
    width: 100%;
    text-align: center;
  }
  
  #configurations .card-full-weight .body .construction-weight h6 {
    width: 100%;
    text-align: center;
  }
  
  #client-journey-tab .card-full-weight .body .construction-weight h6 a {
    text-decoration: none;
    font-weight: 600;
    color: #4C84FF;
  }
  
  #configurations .card-full-weight .body .construction-weight h6 a {
    text-decoration: none;
    font-weight: 600;
    color: #4C84FF;
  }
  
  #client-journey-tab .card-full-weight .body .construction-weight img {
    width: 200px;
    text-align: center;
  }
  
  #configurations .card-full-weight .body .construction-weight img {
    width: 200px;
    text-align: center;
  }
  
  
  
  
  
  
  
  
  
  /*ELEMENTS TO HIDE ON LOAD*/
  #profile .card-full-weight .header-options .header-center .type-graph img:last-of-type {
    display: none;
  }
  #profile .card-full-weight .header-options .header-center .type-table img:first-of-type {
    display: none;
  }
  #profile .card-full-weight .header-options .header-center .type-table-weight-goal img:first-of-type {
    display: none;
  }
  #profile .card-full-weight .header-options .header-center .type-goals img:first-of-type {
    display: none;
  }
  #profile .card-full-weight .header-options .placeholder-table {
    display: none;
  }
  #profile .card-full-weight .header-options .range-table {
    display: none;
  }
  #profile .card-full-weight .header-options .placeholder-goals {
    display: none;
  }
  #profile .card-full-weight .header-options .goal-editor {
    display: none;
  }
  #profile .card-full-weight .body .table-body {
    display: none;
  }
  #profile .card-full-weight .body .goals-body {
    display: none;
  }
  
  
  #client-journey-tab .card-full-weight .header-options .header-center .type-graph img:last-of-type {
    display: none;
  }
  /* #client-journey-tab .card-full-weight .header-options .header-center .type-table img:first-of-type {
    display: none;
  } */
  #client-journey-tab .card-full-weight .header-options .header-center .type-table img:last-of-type {
    display: none;
  }
  #client-journey-tab .card-full-weight .header-options .header-center .type-table-weight-goal img:first-of-type {
    display: none;
  }
  #client-journey-tab .card-full-weight .header-options .header-center .type-goals img:first-of-type {
    display: none;
  }
  #client-journey-tab .card-full-weight .header-options .placeholder-table {
    display: none;
  }
  #client-journey-tab .card-full-weight .header-options .range-table {
    display: none;
  }
  #client-journey-tab .card-full-weight .header-options .placeholder-goals {
    display: none;
  }
  #client-journey-tab .card-full-weight .header-options .goal-editor {
    display: none;
  }
  /* #client-journey-tab .card-full-weight .body .table-body {
    display: none;
  } */
  #client-journey-tab .card-full-weight .body .goals-body {
    display: none;
  }
  
  
  #configurations .card-full-weight .header-options .header-center .type-graph img:last-of-type {
    display: none;
  }
  /* #configurations .card-full-weight .header-options .header-center .type-table img:first-of-type {
    display: none;
  } */
  #configurations .card-full-weight .header-options .header-center .type-table img:last-of-type {
    display: none;
  }
  #configurations .card-full-weight .header-options .header-center .type-table-weight-goal img:first-of-type {
    display: none;
  }
  #configurations .card-full-weight .header-options .header-center .type-goals img:first-of-type {
    display: none;
  }
  #configurations .card-full-weight .header-options .placeholder-table {
    display: none;
  }
  #configurations .card-full-weight .header-options .range-table {
    display: none;
  }
  #configurations .card-full-weight .header-options .placeholder-goals {
    display: none;
  }
  #configurations .card-full-weight .header-options .goal-editor {
    display: none;
  }
  /* #configurations .card-full-weight .body .table-body {
    display: none;
  } */
  #configurations .card-full-weight .body .goals-body {
    display: none;
  }
  
  /*----------------------------------------------- PROFILE (UNIQE-WEIGHT) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- PROFILE (UNIQE-TAE) -----------------------------------------------*/
  #profile .card-full-tae {
    height: 340px;
  }
  
  /* #comorbidity-view .card-full-tae {
    height: 340px;
  } */
  
  @media screen and (max-width: 768px) {
    #profile .card-full-tae .header h4:first-of-type {
      display: none;
    }
  }
  
  /* @media screen and (max-width: 768px) {
    #comorbidity-view .card-full-tae .header h4:first-of-type {
      display: none;
    }
  } */
  @media screen and (min-width: 769px) {
    #profile .card-full-tae .header h4:last-of-type {
      display: none;
    }
  }
  
  /* @media screen and (min-width: 769px) {
    #comorbidity-view .card-full-tae .header h4:last-of-type {
      display: none;
    }
  } */
  
  #profile .card-full-tae .body {
    display: flex;
    height: calc(100% - 59px);
    justify-content: center;
    position: relative;
  }
  
  #comorbidity-view .card-full-tae .body {
    /* display: flex; */
    /* height: calc(100% - 59px); */
    justify-content: center;
    position: relative;
  }
  
  #profile .card-full-tae .body .daily {
    width: 100%;
    max-width: 650px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
  }
  
  
  
  /*INSUFFICIENT DATA*/
  #profile .card-full-tae .body .daily .insufficient {
    width: 100%;
    max-width: 220px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  #profile .card-full-tae .body .daily .insufficient h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  #profile .card-full-tae .body .daily .insufficient h6 {
    width: 100%;
    text-align: center;
  }
  
  
  
  /*DIAGRAM*/
  #profile .card-full-tae .body .daily .diagram {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
  }
  
  #profile .card-full-tae .body .daily .diagram .target {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  #profile .card-full-tae .body .daily .diagram .target h3 {
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
  
  #profile .card-full-tae .body .daily .diagram .target h5 {
    width: 100%;
    text-align: center;
    margin-top: -2px;
    font-weight: 600;
  }
  
  #profile .card-full-tae .body .daily .diagram .target img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  #profile .card-full-tae .body .daily .diagram .target img:hover {
    background-color: #E5EDFF;
    cursor: pointer;
  }
  
  
  
  /*MEAL DIV*/
  #profile .card-full-tae .body .daily .diagram [class*="meal"] {
    width: 72px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    z-index: 2;
  }
  
  #profile .card-full-tae .body .daily .diagram [class*="meal"] {
    position: relative;
  }
  
  #profile .card-full-tae .body .daily .diagram [class*="meal"] > img {
    width: 32px;
    height: 32px;
    border: 4px solid white;
    border-radius: 50%;
    display: block;
  }
  
  #profile .card-full-tae .body .daily .diagram [class*="meal"] > h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  #profile .card-full-tae .body .daily .diagram [class*="meal"] > h6 {
    width: 100%;
    margin-top: -2px;
    text-align: center;
  }
  
  
  
  /*MIDDLE*/
  #profile .card-full-tae .body .daily .diagram .middle {
    width: calc(100% - 144px + 72px);
    margin-left: -36px;
    margin-right: -36px;
    height: 16px;
    margin-top: 12px;
    background-color: #F5F6F8;
  }
  
  
  
  
  
  
  
  
  
  
  /*TIMELINE (VIEW BUTTON)*/
  #profile .card-full-tae .body .view-timeline {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    bottom: 4px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #E4EDFF;
  }
  
  #profile .card-full-tae .body .view-timeline:hover {
    background-color: #C9DAFF;
  }
  
  
  
  /*TIMELINE PROMPTED*/
  /*A COUPLE OF OVERRIDES (GENERIC PROMPT STYLING IN MAIN.CSS)*/
  #prompts .prompt-tae-timeline {
    background-color: rgb(250,250,250);
  }
  
  #prompts .prompt-tae-timeline .timeline {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 40px;
    justify-content: center;
    background-color: rgb(250,250,250);
    overflow-y: scroll;
  }
  
  #prompts .prompt-tae-timeline .timeline .header {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
  }
  
  /*PROMPT HEADER IMG*/
  #prompts .prompt-tae-timeline .timeline .header img {
    width: 40px;
    height: 40px;
  }
  
  /*PROMPT HEADER H3*/
  #prompts .prompt-tae-timeline .timeline .header h3 {
    font-weight: 600;
    margin-left: 4px;
  }
  
  
  
  
  
  
  
  /*RANGE*/
  #prompts .prompt-tae-timeline .timeline .header .range-timeline {
    width: 100%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  
  /*RANGE BUTTON*/
  #prompts .prompt-tae-timeline .timeline .header .range-timeline .range-button {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  #prompts .prompt-tae-timeline .timeline .header .range-timeline .range-button {
    width: 60px;
  }
  #prompts .prompt-tae-timeline .timeline .header .range-timeline .range-button:not(:last-child) {
    margin-right: 8px;
  }
  
  /*HEADER-OPTIONS RANGE-BUTTON H5*/
  #prompts .prompt-tae-timeline .timeline .header .range-timeline .range-button h6 {
    font-weight: 600;
  }
  
  
  
  /*TIMELINE FRAMEWORK*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper {
    width: 100%;
    max-height: calc(100vh - 316px);
    overflow-y: scroll;
  }
  
  /*TIMELINE CONTAINER*/
  #prompts .prompt-tae-timeline .timeline .timeline-container {
    position: relative;
    z-index: 0;
  }
  
  /*TIMELINE BAR*/
  #prompts .prompt-tae-timeline .timeline .timeline-container .timeline-bar {
    width: 12px;
    height: calc(100% - 24px);
    position: absolute;
    top: 12px;
    left: 0px;
    right: 0px;
    margin: auto;
    background-color: #F3F4F5;
  }
  
  
  
  /*TIMELINE WIDTHS*/
  @media screen and (max-width: 768px) {
    #prompts .prompt-tae-timeline .timeline .timeline-wrapper {
      padding-left: calc((100% - 272px)/2);
      padding-right: calc((100% - 272px)/2);
    }
  }
  @media screen and (min-width: 769px) {
    #prompts .prompt-tae-timeline .timeline .timeline-wrapper {
      padding-left: calc((100% - 372px)/2);
      padding-right: calc((100% - 372px)/2);
    }
  }
  @media screen and (max-width: 768px) {
    #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble {
      width: 108px;
    }
  }
  @media screen and (min-width: 769px) {
    #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble {
        width: 158px;
    }
  }
  @media screen and (max-width: 768px) {
    #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-bubble {
      width: 124px;
    }
  }
  @media screen and (min-width: 769px) {
    #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-bubble {
        width: 174px;
    }
  }
  
  
  
  /*MEAL*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    border-radius: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  /*ADDED IN JS*/
  .enlarged-timeline-meal {
    background-color: white;
    border-radius: 20px !important;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
  }
  
  /*TIMELINE MEAL BUBBLE*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-end;
    position: relative;
    background-color: white;
    border-radius: 20px;
    cursor: normal;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble:after, 
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble:before {
    position: absolute;
    width: 0px; 
    height: 0px;
    top: 8px;
    right: -8px;
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    border-left: 12px solid white;
    content: " ";
  }
  /*ADDED IN JS*/
  .enlarged-timeline-bubble {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0))!important;  
  }
  
  
  
  /*TIMELINE MEAL*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-time {
    width: calc(100% - 16px);
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 4px;
    font-weight: 700;
    text-align: right;
  }
  
  /*TIMELINE MEAL DATE*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-date {
    width: calc(100% - 16px);
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 4px;
    text-align: right;
    font-weight: 600;
    color: #9D9D9D;
    margin-top: -2px;
  }
  
  /*TIMELINE MEAL DATE*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details {
    display: none;
  }
  
  /*TIMELINE MEAL DETAILS DESCRIPTION*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details .timeline-meal-description {
    width: calc(100% - 16px);
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 8px;
    text-align: right;
    font-weight: 600;
    line-height: 12px;
    color: #9D9D9D;
    word-break: break-all;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details .timeline-meal-description span {
    font-weight: 700;
    color: #858796;
  }
  
  /*TIMELINE MEAL DETAILS SUPERFOODS*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details .timeline-meal-superfoods {
    width: calc(100% - 16px);
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: right;
    font-weight: 600;
    line-height: 12px;
    color: #9D9D9D;
    word-break: break-all;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details .timeline-meal-superfoods span {
    font-weight: 700;
    color: #858796;
  }
  
  /*TIMELINE MEAL DETAILS FOODS TO AVOID*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details .timeline-meal-foods-to-avoid {
    width: calc(100% - 16px);
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: right;
    font-weight: 600;
    line-height: 12px;
    color: #9D9D9D;
    word-break: break-all;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details .timeline-meal-foods-to-avoid span {
    font-weight: 700;
    color: #858796;
  }
  
  
  /*TIMELINE MEAL PHOTO*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-photo {
    width: 32px;
    height: 32px;
    margin-left: 6px;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    z-index: 2;
    border: 4px solid white;
    box-sizing: content-box;
    box-shadow: 0px 2px 4px rgba(0,0,0,.2);
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  /*ADDED IN JS*/
  @media screen and (max-width: 768px) {
    .enlarged-timeline-photo {
      width: 142px !important;
      height: 142px !important;
      border: 8px solid white !important;
      box-shadow: 0px 2px 4px rgba(0,0,0,0) !important;
    }
  }
  /*ADDED IN JS*/
  @media screen and (min-width: 769px) {
    .enlarged-timeline-photo {
      width: 192px !important;
      height: 192px !important;
      border: 8px solid white !important;
      box-shadow: 0px 2px 4px rgba(0,0,0,0) !important;
    }
  }
  
  /*TIMELINE MEAL PHOTO IMG*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-photo img {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  
  /*MEAL-FEEDBACK-INDICATOR*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .meal-feedback-indicator {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 32px;
    height: 32px;
  }
  
  /*TIMELINE MEAL FEEDBACK*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details .timeline-meal-feedback {
    width: calc(100% - 16px);
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: right;
    font-weight: 600;
    line-height: 12px;
    color: #9D9D9D;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-meal .timeline-bubble .timeline-meal-details .timeline-meal-feedback span {
    font-weight: 700;
    color: #858796;
  }
  
  
  /*TIMELINE MEAL PHOTO MINIMIZE*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-photo .timeline-minimize {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 3;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-photo .timeline-minimize > div {
    width: 0px;
    height: 0px;
    margin-top: 4px;
    margin-right: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* z-index: 4; */
    background-color: #4C84FF;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-photo .timeline-minimize > div:hover {
    box-shadow: 0px 0px 0px 2px #245CD7;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-photo .timeline-minimize > div span {
    width: 8px;
    height: 2px;
    border-radius: 1px;
    background-color: white;
  }
  /*ADDED IN JS*/
  .enlarged-timeline-minimize {
    width: 16px !important;
    height: 16px !important;
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  
  
  
  /*TIMELINE FAST*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    margin-top: 24px;
    margin-bottom: 24px;
  }
  
  /*TIMELINE FAST BUBBLE*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-bubble {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    background-color: white;
    white-space: nowrap;
    position: relative;
    border-radius: 20px;
    margin-left: -4px;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-bubble:after, 
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-bubble:before {
    position: absolute;
    width: 0px; 
    height: 0px;
    top: 8px;
    left: -6px;
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    content: " ";
  }
  
  /*TIMELINE FAST TEXT*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-bubble h5 {
    width: calc(100% - 10px);
    margin-left: 10px;
    margin-top: 4px;
    font-weight: 700;
    text-align: left;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-bubble h6 {
    margin-left: 10px;
    margin-bottom: 4px;
    text-align: left;
    font-weight: 600;
    margin-top: -2px;
  }
  
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-bubble .timeline-target-met {
    width: 15px;
    height: 15px;
    margin-left: 2px;
    margin-top: -2px;
  }
  
  /*TIMELINE NIGHT SYMBOL*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-symbol {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-top: 12px;
    margin-right: 6px;
    box-shadow: 0px 1px 2px rgba(0,0,0,.2);
  }
  
  /*TIMELINE NIGHT SYMBOL IMG*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-symbol img {
    display: block;
    width: 16px;
    height: 16px;
  }
  
  
  /*FAST BUBBLE COLORS*/
  /*BUBBLE-GREEN*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-green {
    background-color: #00CC91 !important; 
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-green:after, 
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-green:before {
    border-right: 10px solid #00CC91;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-green h5 {
    color: white;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-green h6 {
    color: white;
  }
  
  /*BUBBLE-YELLOW*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-yellow {
    background-color: #FFC720 !important; 
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-yellow:after, 
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-yellow:before {
    border-right: 10px solid #FFC720;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-yellow h5 {
    color: white;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-yellow h6 {
    color: white;
  }
  
  /*BUBBLE-RED*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-red {
    background-color: #FC5834 !important; 
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-red:after, 
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-red:before {
    border-right: 10px solid #FC5834;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-red h5 {
    color: white;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-red h6 {
    color: white;
  }
  
  /*BUBBLE-GREY*/
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-grey {
    background-color: #F5F6F8 !important; 
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-grey:after, 
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-grey:before {
    border-right: 10px solid #F5F6F8;
  }
  #prompts .prompt-tae-timeline .timeline .timeline-wrapper .timeline-fast .timeline-fast-bubble-grey h6 {
    opacity: 70%;
  }
  
  
  
  
  
  
  
  
  
  
  /*PROMPTS TAE - EDITOR (GENERIC PROMPT STYLING IN MAIN.CSS)*/
  #prompts .prompt-tae-target-edit .editor {
    width: 100%;
    position: relative;
    margin-top: 60px;
  }
  
  
  /*RANGE-WRAP*/
  #prompts .prompt-tae-target-edit .editor .range-wrap {
    position: relative;
  }
  
  /*RANGE*/
  #prompts .prompt-tae-target-edit .editor .range-wrap .range {
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    height: 16px;
    border-radius: 8px;
    background: linear-gradient(90deg, #FC5834 25%, #FFC720 25%, #FFC720 50%, #00CC91 50%);
  }
  .range:focus {
      outline: none;
  }
  
  /*RANGE THUMB*/
  #prompts .prompt-tae-target-edit .editor .range-wrap .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 24px;
    width: 24px;
    border: 3px solid #376CDF;
    border-radius: 50%;
    background: #4D85FF;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(0,0,0,.4);
  }
  
  /*BUBBLE*/
  #prompts .prompt-tae-target-edit .editor .range-wrap .bubble {
    background: white;;
    color: white;
    height: 40px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius: 20px;
    top: -44px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Source Sans Pro', sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    margin: 0px;
    color: #858796;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: -1px;
    box-shadow: 0px 10px 20px rgba(0,0,0,.2);
  }
  #prompts .prompt-tae-target-edit .editor .range-wrap .bubble::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid white;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    margin-top: -1px;
  }
  
  
  
  /*PROMPTS TAE -  LABELS*/
  #prompts .prompt-tae-target-edit .editor .range-labels {
    width: calc(100% - 24px);
    padding-left: 14px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
  }
  
  /*PROMPTS TAE - LABEL*/
  #prompts .prompt-tae-target-edit .editor .range-labels .label {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 40px;
  }
  
  /*PROMPTS TAE - LABEL DIV*/
  #prompts .prompt-tae-target-edit .editor .range-labels .label:first-of-type div {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  #prompts .prompt-tae-target-edit .editor .range-labels .label:last-of-type div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  /*PROMPTS TAE - LABEL H6*/
  #prompts .prompt-tae-target-edit .editor .range-labels .label h6 {
    width: 100%;
  }
  #prompts .prompt-tae-target-edit .editor .range-labels .label:first-of-type h6 {
    text-align: left;
  }
  #prompts .prompt-tae-target-edit .editor .range-labels .label:last-of-type h6 {
    text-align: right;
  }
  
  /*PROMPTS TAE - LABEL LINE*/
  #prompts .prompt-tae-target-edit .editor .range-labels .label span {
    height: 12px;
    width: 1px;
    background-color: rgb(184,184,184);
  }
  /*----------------------------------------------- PROFILE (UNIQE-TAE) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- TICKETS -----------------------------------------------*/
  #tickets {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 920px;
  }
  
  #tickets .create {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  #tickets .create > div:first-of-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 840px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 60px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (min-width: 769px) {
    .create > div {
      height: 340px;
    }
  }
  
  
  
  /*HEADER*/
  #tickets .create .header {
    margin-top: 32px;
    width: 100%;
  }
  
  #tickets .create .header h3 {
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
  
  #tickets .create .header h5 {
    width: 100%;
    text-align: center;
    font-weight: 400;
    margin-bottom: 16px;
  }
  
  
  
  /*FORM*/
  #tickets .create form {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    #tickets .create form {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media screen and (min-width: 769px) {
    #tickets .create form {
      justify-content: space-between;
    }
  }
  
  
  
  /*COLUMN-ONE*/
  #tickets .create .column-one {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  @media screen and (max-width: 768px) {
    #tickets .create .column-one {
      width: 100%;
    }
  }
  @media screen and (min-width: 769px) {
    #tickets .create .column-one {
      width: calc(100% - 280px);
    }
  }
  
  
  
  /*INPUT BOXES*/
  #tickets .create .column-one .textarea-text {
    height: 148px;
  }
  
  
  
  /*WRAPPER-TWO*/
  #tickets .create .column-two {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  @media screen and (max-width: 768px) {
    #tickets .create .column-two {
      width: 100%;
      justify-content: center;
      margin-top: 12px;
    }
  }
  @media screen and (min-width: 769px) {
    #tickets .create .column-two {
      width: 260px;
      margin-left: 20px;
    }
  }
  
  
  
  /*WRAPPER-TWO DIV (CHECKBOX)*/
  #tickets .column-two > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    border-radius: 8px;
    padding-top: 45px;
    padding-left: 12px;
    padding-bottom: 30px;
    margin-bottom: 12px;
    background-color: #F8F9FC;
  }
  
  
  
  /*BUTTONS (MORE)*/
  #tickets .create button {
    width: 100%;
    height: 42px;
    border-radius: 4px;
    background-color: #4E85FF;
    border: 0px;
  }
  @media screen and (max-width: 768px) {
    #tickets .create button {
      max-width: 240px;
    }
  }
  @media screen and (min-width: 769px) {
    #tickets .create button {
      max-width: 260px;
      margin-bottom: 5px;
    }
  }
  
  #tickets .create button a {
    text-decoration: none;
  }
  
  #tickets .create button h5 {
    color: white;
    font-weight: 600;
    line-height: 42px;
  }
  
  
  .shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  
  
  /*CARDS*/
  #tickets .cards {
    width: 100%;
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  
  /*CARD*/
  #tickets .cards .card {
    height: 340px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
  }
  
  @media screen and (min-width: 1144px) {
    #tickets .cards .card {
      width: 100%;
      max-width: 280px;
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    #tickets .cards .card {
      width: calc(50% - 20px);
    }
  }
  @media screen and (max-width: 843px) {
    #tickets .cards .card {
      width: calc(100% - 20px);
    }
  }
  
  #tickets .cards .card:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  
  
  
  
  
  /*HEADER*/
  #tickets .cards .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  #tickets .cards .header img {
    width: 20px;
    height: 20px;
    margin-top: 32px;
  }
  
  #tickets .cards .header h4 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  #tickets .cards .header h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }
  
  
  
  /*DESCRIPTION*/
  #tickets .cards .description {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    height: 200px;
    overflow-y: scroll;
  }
  
  #tickets .cards .description h5 {
    width: 100%;
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  #tickets .cards .description h6 {
    width: 100%;
    font-weight: 400;
  }
  
  
  
  /*COMPLETED (APPLIED IN JS FILE)*/
  .completed {
    opacity: .3;
  }
  /*----------------------------------------------- TICKETS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* REMOVE AND REPLACE PREVIOUS SESSIONS-SECTION CSS CODE WITH BELOW */
  /*----------------------------------------------- SESSIONS -----------------------------------------*/
  #sessions {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 920px;
  }
  
  #sessions [class*="cards"] h4,
  #sessions [class*="cards"] h5,
  #sessions [class*="cards"] h6 {
    color: #82819e;
  }
  
  
  /*HEADER*/
  #sessions .header {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  
  /*CARDS*/
  #sessions [class*="cards"] {
    width: 100%;
    max-width: 920px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  
  #sessions [class*="cards"] h5 {
    flex: 1;
    margin-left: 22px;
    margin-right: 22px;
    font-weight: 600;
    margin-bottom: 4px;
    margin-top: 60px;
  }
  
  #sessions [class*="cards"] h5:first-of-type {
    margin-top: 0px;
  }
  
  
  
  /*CARD*/
  #sessions [class*="cards"] .card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 12px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
    width: 100%;
    /*max-width: 880px;*/
    padding: 12px;
  }
  
  #sessions [class*="cards"] .card:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24);
  }
  
  #sessions [class*="cards"] .card > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100% - 64px);
  }
  
  @media screen and (max-width: 843px) {
    #sessions [class*="cards"] .card {
      width: calc(100% - 44px);
    }
  }
  
  
  
  /*CARD-THIRD*/
  @media screen and (min-width: 1144px) {
    #sessions [class*="cards"] .card-third {
      width: 100%;
      max-width: 256px;
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    #sessions [class*="cards"] .card-third {
      width: calc(50% - 44px);
    }
  }
  
  
  
  /*CARD CALL ITEM*/
  #sessions .card .call-item {
    display: flex;
  }
  
  #sessions .card .call-item h4 {
    font-weight: 600;
    letter-spacing: -0.75px;
    flex: 1;
  }
  
  
  
  /*CALL ITEM ICONS*/
  #sessions .card .call-item .icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  #sessions .card .call-item .icons img {
    width: 23px;
    height: 23px;
    margin-left: 4px;
  }
  
  
  
  /*CALL ITEM SPAN*/
  #sessions .card .call-item span {
    margin: 0 0 0 4px;
    padding: 2px 8px;
    border-radius: 18px;
    background-color: #f5f6f8;
  }
  
  #sessions .card .call-item span h5 {
    margin: 0px;
  }
  
  
  
  /*CALL ITEM ICON*/
  #sessions .icons .icon {
    display: flex;
    position: relative;
  }
  
  #sessions .icons .icon:hover .tooltip {
    display: flex;
    justify-content: center;
    opacity: unset;
  }
  
  
  
  /*ICON BADGE*/
  #sessions .card .icons .icon .badge {
    position: absolute;
    top: -5px;
    right: 0px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    background-color: #F5A623;
  }
  
  #sessions .card .icons .icon .badge h6 {
    font-weight: 700;
    color: white;
    letter-spacing: -.5px;
    font-size: 8px;
  }
  
  
  
  /*ICON TOOLTIP*/
  #sessions .icons .icon .tooltip {
    display: none;
    position: absolute;
    top: -40px;
    right: calc(100% - 50px);
    margin: auto;
    width: auto;
    padding: 0px 13px;
    background-color: white;
    height: 40px;
    border-radius: 20px;
    align-items: center;
    white-space: nowrap;
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24);
    z-index: 2;
  }
  
  /* #sessions .icons .icon .tooltip:before, #sessions .icons .icon .tooltip:after {
    position: absolute;
    width: 0px;
    height: 0px;
    bottom: -9px;
    right: 23px;
    margin: auto;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid white;
    content: " ";
  } */
  
  #sessions .icons .icon .tooltip h6 {
    color: #4c84ff;
    letter-spacing: normal;
  }
  
  
  
  /*CARD H6*/
  #sessions [class*="cards"] .card h6 {
    font-weight: 600;
    letter-spacing: -0.25px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  
  
  /*CARD IMG*/
  #sessions [class*="cards"] .card > img {
    width: 40px;
    display: block;
    margin: 0 8px 0 0;
  }
  
  
  
  /*STATUS SYMBOL*/
  #sessions [class*="cards"] .card .status-symbol {
    width: 18px;
    position: absolute;
  }
  
  
  
  /*CARD A*/
  #sessions [class*="cards"] .card a {
    width: 197px;
    height: 40px;
    height: 100%;
    position: absolute;
    border-radius: 20px;
  }
  
  
  
  /*CARDS EMPTY STATE*/
  #sessions [class*="cards"] .empty-state {
    background-color: #f2f2f5;
  }
  
  
  
  /*CARDS SEND LINK*/
  #sessions .send-link {
    margin-top: 8px;
    margin-bottom: 20px;
    max-width: 197px;
    margin-left: auto;
    margin-right: auto;
  }
  
  #sessions .send-link h5 {
    color: #ffffff;
    margin: 0px;
    letter-spacing: normal;
  }
  
  
  
  /*UNTRIED CLASSES*/
  #sessions .untried-classes {
    width: 100%;
    margin-top: 60px;
  }
  /*----------------------------------------------- SESSIONS (END) -----------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- TO DO'S -----------------------------------------------*/
  /*TO DO'S*/
  #to-dos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 920px;
  }
  
  /*CREATE*/
  #to-dos .create {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  #to-dos .create > div:first-of-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 840px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 60px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (min-width: 769px) {
    #to-dos .create > div {
      height: 340px;
    }
  }
  
  
  
  /*CREATE HEADER*/
  #to-dos .create .header {
    margin-top: 32px;
    width: 100%;
  }
  
  #to-dos .create .header h3 {
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
  
  #to-dos .create .header h5 {
    width: 100%;
    text-align: center;
    font-weight: 400;
    margin-bottom: 16px;
  }
  
  
  
  /*CREATE FORMS WRAPPER*/
  #to-dos .create .forms-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  
  
  /*CREATE FORM*/
  @media screen and (max-width: 768px) {
    #to-dos .create .forms-wrapper form {
      width: 100%;
    }
  }
  @media screen and (min-width: 769px) {
    #to-dos .create .forms-wrapper form {
      width: calc(100% - 280px);
    }
  }
  
  
  
  #to-dos .create .forms-wrapper form .textarea-text {
    height: 148px;
  }
  
  
  
  /*CREATE FORMS CTA*/
  @media screen and (max-width: 768px) {
    #to-dos .create .forms-wrapper .forms-cta {
      width: 100%;
    }
  }
  
  #to-dos .create .forms-wrapper .forms-cta > div {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-end;
    margin: 0px;
  }
  @media screen and (max-width: 768px) {
    #to-dos .create .forms-wrapper .forms-cta > div {
      width: 100%;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: 769px) {
    #to-dos .create .forms-wrapper .forms-cta > div {
      height: 160px;
      width: 260px;
      margin-left: 20px;
    }
  }
  
  
  
  /*CREATE MESSAGE CONFIRM*/
  #to-dos .create .forms-wrapper .forms-cta #message-confirm {
    display: none;
    align-items: flex-end;
  }
  
  /*CREATE MESSAGE CONFIRM > DIV*/
  #to-dos .create .forms-wrapper .forms-cta #message-confirm > div {
    width: 240px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /*CREATE CHARACTER COUNTER*/
  @media screen and (max-width: 768px) {
    #to-dos .create .forms-wrapper .forms-cta .character-counter {
      width: 100%;
      text-align: center;
      margin-bottom: 12px;
    }
  }
  
  /*CREATE CONFIRM QUESTION TEXT*/
  #to-dos .create .forms-wrapper .forms-cta #message-confirm h4 {
    align-self: flex-start;
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    #to-dos .create .forms-wrapper .forms-cta #message-confirm h4 {
      margin-bottom: 12px;
    }
  }
  
  
  
  /*CREATE BUTTONS*/
  #to-dos .create .forms-wrapper .forms-cta button {
    height: 42px;
    border-radius: 4px;
    border: 0px;
  }
  @media screen and (max-width: 768px) {
    #to-dos .create .forms-wrapper .forms-cta button {
      width: 100%;
      max-width: 240px;
    }
  }
  @media screen and (min-width: 769px) {
    #to-dos .create .forms-wrapper .forms-cta button {
      width: 260px;
      align-self: flex-end;
    }
  }
  
  #to-dos .create .forms-wrapper .forms-cta button h5 {
    font-weight: 600;
    line-height: 42px;
  }
  
  /*CREATE BUTTON COLORING*/
  #to-dos .create .forms-wrapper .forms-cta #message-send button {
    background-color: #4E85FF;
  }
  #to-dos .create .forms-wrapper .forms-cta #message-send button h5 {
    color: white;
  }
  
  #to-dos .create .forms-wrapper .forms-cta #message-confirm button:first-child {
    background-color: white;
    margin-bottom: 4px;
  }
  #to-dos .create .forms-wrapper .forms-cta #message-confirm button:first-child h5 {
    color: #4E85FF;
  }
  #to-dos .create .forms-wrapper .forms-cta #message-confirm button:first-child:hover {
    background-color: #E5EDFF;
  }
  
  #to-dos .create .forms-wrapper .forms-cta #message-confirm button:last-child {
    background-color: #4E85FF;
  }
  #to-dos .create .forms-wrapper .forms-cta #message-confirm button:last-child h5 {
    color: white;
  }
  
  
  
  /*CREATE SHAKE ANIMATION*/
  .shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  
  
  
  
  
  
  
  
  /*CARDS*/
  #to-dos .cards {
    width: 100%;
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  
  /*CARDS CARD*/
  #to-dos .cards .card {
    height: 340px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
  }
  @media screen and (min-width: 1144px) {
    #to-dos .cards .card {
      width: 100%;
      max-width: 280px;
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    #to-dos .cards .card {
      width: calc(50% - 20px);
    }
  }
  @media screen and (max-width: 843px) {
    #to-dos .cards .card {
      width: calc(100% - 20px);
    }
  }
  #to-dos .cards .card:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  
  
  /*CARDS HEADER*/
  #to-dos .cards .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  #to-dos .cards .header img {
    width: 20px;
    height: 20px;
    margin-top: 32px;
  }
  
  #to-dos .cards .header h4 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  #to-dos .cards .header h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px;
  }
  
  
  
  /*CARDS DESCRIPTION*/
  #to-dos .cards .description {
    display: flex;
    flex-wrap: wrap;
    height: 200px;
    overflow-y: scroll;
    justify-content: center;
    align-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  #to-dos .cards .description h5 {
    width: 100%;
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  #to-dos .cards .description h6 {
    width: 100%;
    font-weight: 400;
  }
  
  
  
  /*CARDS COMPLETED (APPLIED IN JS FILE)*/
  .completed {
    opacity: .3;
  }
  /*----------------------------------------------- TO DO'S (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- TEXTS -----------------------------------------------*/
  #texts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 920px;
  }
  
  #texts .create {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  #texts .create > div:first-of-type {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 840px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 60px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (min-width: 769px) {
    #texts .create > div {
      height: 340px;
    }
  }
  
  
  
  /*HEADER*/
  #texts .create .header {
    margin-top: 32px;
    width: 100%;
  }
  
  #texts .create .header h3 {
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
  
  #texts .create .header h5 {
    width: 100%;
    text-align: center;
    font-weight: 400;
    margin-bottom: 16px;
  }
  
  
  
  /*FORMS WRAPPER*/
  #texts .create .forms-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  
  
  /*FORM*/
  @media screen and (max-width: 768px) {
    #texts .create .forms-wrapper form {
      width: 100%;
    }
  }
  @media screen and (min-width: 769px) {
    #texts .create .forms-wrapper form {
      width: calc(100% - 280px);
    }
  }
  
  
  /*TEXTAREA-TEXT*/
  #texts .create .forms-wrapper form .textarea-text {
    height: 148px;
  }
  
  
  
  /*FORMS CTA*/
  @media screen and (max-width: 768px) {
    #texts .create .forms-wrapper .forms-cta {
      width: 100%;
    }
  }
  
  #texts .create .forms-wrapper .forms-cta > div {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-end;
    margin: 0px;
  }
  @media screen and (max-width: 768px) {
    #texts .create .forms-wrapper .forms-cta > div {
      width: 100%;
      justify-content: center;
      margin-top: 12px;
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: 769px) {
    #texts .create .forms-wrapper .forms-cta > div {
      height: 160px;
      width: 260px;
      margin-left: 20px;
    }
  }
  
  
  
  /*MESSAGE CONFIRM*/
  #texts .create .forms-wrapper .forms-cta #message-confirm {
    display: none;
    align-items: flex-end;
  }
  
  /*MESSAGE CONFIRM > DIV*/
  #texts .create .forms-wrapper .forms-cta #message-confirm > div {
    width: 240px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /*CHARACTER COUNTER*/
  @media screen and (max-width: 768px) {
    #texts .create .forms-wrapper .forms-cta .character-counter {
      width: 100%;
      text-align: center;
      margin-bottom: 12px;
    }
  }
  
  /*CONFIRM QUESTION TEXT*/
  #texts .create .forms-wrapper .forms-cta #message-confirm h4 {
    align-self: flex-start;
    width: 100%;
    text-align: center;
    font-weight: 600;
  }
  @media screen and (max-width: 768px) {
    #texts .create .forms-wrapper .forms-cta #message-confirm h4 {
      margin-bottom: 12px;
    }
  }
  
  
  
  /*BUTTONS*/
  #texts .create .forms-wrapper .forms-cta button {
    height: 42px;
    border-radius: 4px;
    border: 0px;
  }
  @media screen and (max-width: 768px) {
    #texts .create .forms-wrapper .forms-cta button {
      width: 100%;
      max-width: 240px;
    }
  }
  @media screen and (min-width: 769px) {
    #texts .create .forms-wrapper .forms-cta button {
      width: 260px;
      align-self: flex-end;
    }
  }
  
  #texts .create .forms-wrapper .forms-cta button h5 {
    font-weight: 600;
    line-height: 42px;
  }
  
  /*BUTTON COLORING*/
  #texts .create .forms-wrapper .forms-cta #message-send button {
    background-color: #4E85FF;
  }
  #texts .create .forms-wrapper .forms-cta #message-send button h5 {
    color: white;
  }
  
  #texts .create .forms-wrapper .forms-cta #message-confirm button:first-child {
    background-color: white;
    margin-bottom: 4px;
  }
  #texts .create .forms-wrapper .forms-cta #message-confirm button:first-child h5 {
    color: #4E85FF;
  }
  #texts .create .forms-wrapper .forms-cta #message-confirm button:first-child:hover {
    background-color: #E5EDFF;
  }
  
  #texts .create .forms-wrapper .forms-cta #message-confirm button:last-child {
    background-color: #4E85FF;
  }
  #texts .create .forms-wrapper .forms-cta #message-confirm button:last-child h5 {
    color: white;
  }
  
  
  
  /*SHAKE ANIMATION*/
  .shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  
  
  
  
  
  
  
  
  
  /*MESSAGES*/
  #texts .messages {
    width: 100%;
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  
  
  
  /*HOLDS TIME AND WHITE BUBBLE*/
  #texts .messages .message {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 32px;
    margin-left: 10px;
    margin-right: 10px;  
  }
  
  /*TIME*/
  #texts .messages .message > h6 {
    width: 100%;
    text-align: right;
    margin-right: 20px;
    margin-bottom: 4px;
    opacity: .5;  
  }
  
  /*WHITE BUBBLE*/
  #texts .messages .message > div {
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 15px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
  }
  #texts .messages .message > div:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  
  
  /*HOLDER OF COACH NAME AND IMG*/
  #texts .messages .message > div > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    margin-bottom: 4px;
  }
  
  /*COACH NAME*/
  #texts .messages .message > div > div h5 {
    font-weight: 600;
  }
  
  /*IMG*/
  #texts .messages .message > div > div img {
    width: 32px;
    height: 32px;
  }
  /*----------------------------------------------- TEXTS (END) -----------------------------------------------*/
  
  
  /*----------------------------------------------- CONNECT-DOTS (GENERIC) -----------------------------------------------*/
  #connect-dots {
    width: calc(100% - 20px);
    max-width: 900px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  /*----------------------------------------------- CONNECT-DOTS (GENERIC) -----------------------------------------------*/
  
  
  
  
  /*----------------------------------------------- REPORTS -----------------------------------------------*/
  .reports {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 920px;
  }
  
  
  
  /*HEADER*/
  .reports .header {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  
  /*CARDS*/
  .reports [class*="cards"] {
    width: 100%;
    /* max-width: 900px; */
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  
  /*CARD*/
  .reports [class*="cards"] .card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
  }
  
  @media screen and (min-width: 1144px) {
    .reports [class*="cards"] .card {
      width: 100%;
      max-width: 280px;
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    .reports [class*="cards"] .card {
      width: calc(50% - 20px);
    }
  }
  @media screen and (max-width: 843px) {
    .reports [class*="cards"] .card {
      width: calc(100% - 20px);
    }
  }
  
  .reports [class*="cards"] .card:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  /*CARD H4*/
  .reports [class*="cards"] .card h4 {
    width: 100%;
    font-weight: 600;
    letter-spacing: -.25px;
    margin-top: 32px;
    margin-bottom: 4px;
    color: #4C84FF;
    text-align: center;
  }
  
  /*CARD IMG*/
  .reports [class*="cards"] .card img {
    width: 100%;
    display: block;
    max-width: 160px;
  }
  
  /*CARD A*/
  .reports [class*="cards"] .card a {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 20px;
  }
  /*----------------------------------------------- REPORTS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  /*----------------------------------------------- BLOOD PRESSURE -----------------------------------------------*/
  /*TABLE BODY*/
  #profile .card-full-bp .body .table-body {
    width: calc(100% - 40px);
    display: flex;
    justify-content: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  /*TABLE CONTAINER*/
  #profile .card-full-bp .body .table-body .table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 240px;
    margin-top: 10px;
    margin-bottom: 40px;
    overflow-y: scroll;
    /*(STANDARD COLUMN WIDTH IS 168PX)*/
    max-width: 672px;
  }
  
  /*TABLE STANDARD MODIFICATION*/
  #profile .card-full-bp .body .table-body .table-container .table-standard {
    margin-bottom: 140px;
  }
  
  
  
  /*BP PHOTOS (BP-PHOTO-WRAPPER)*/
  #profile .card-full-bp .body .table-body .table-container .table-standard .bp-photo-wrapper {
    display: flex;
    justify-content: center;
  }
  
  /*BP PHOTOS (BP-PHOTO-CONTAINER)*/
  #profile .card-full-bp .body .table-body .table-container .table-standard .bp-photo-wrapper .bp-photo-container {
    position: relative;
    width: 15px;
  }
  
  /*BP PHOTOS (BP-PHOTO-INDICATOR)*/
  #profile .card-full-bp .body .table-body .table-container .table-standard .bp-photo-wrapper .bp-photo-container .bp-photo-indicator {
    width: 15px;
    height: 15px;
    margin-top: 4px;
  }
  
  
  
  /*MEDIA QUERIES FOR TABLE*/
  @media screen and (max-width: 500px) {
    #profile .card-full-bp .body .table-body .table-container .table-standard tr > *:nth-child(4) {
      display: none;
    }
  }
  @media screen and (max-width: 400px) {
    #profile .card-full-bp .body .table-body .table-container .table-standard tr > *:nth-child(3) {
      display: none;
    }
  }
  
  
  
  /*TOOLTIP FOR TABLE*/
  #profile .card-full-bp .body .table-body .table-container .table-standard .bp-photo-wrapper .bp-photo-container .tooltip-standard {
    height: 160px;
    width: 160px;
    left: -72px;
    top: 20px;
    padding: 0px;
    border-radius: 20px;
    box-shadow: 0px 8px 16px -8px rgba(58,59,69,.1), 0px 8px 20px -8px rgba(58,59,69,.24);
    background-color: white;
    justify-content: center;
  
  }
  
  /*DISPLAY TOOLTIP ONLY ON BP-PHOTO-YES HOVER*/
  #profile .card-full-bp .body .table-body .table-container .table-standard .bp-photo-yes  .bp-photo-container:hover .tooltip-standard {
    display: flex;
  }
  
  #profile .card-full-bp .body .table-body .table-container .table-standard .bp-photo-wrapper .bp-photo-container .tooltip-standard:after, 
  #profile .card-full-bp .body .table-body .table-container .table-standard .bp-photo-wrapper .bp-photo-container .tooltip-standard:before {
    left: 67px;
    top: -24px;
    border-top: 12px solid transparent;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
  }
  
  #profile .card-full-bp .body .table-body .table-container .table-standard .bp-photo-wrapper .bp-photo-container .tooltip-standard img {
    width: 136px;
    height: 136px;
    border-radius: 12px;
  }
  /*----------------------------------------------- BLOOD PRESSURE (END) -----------------------------------------------*/
  
  
  
  .image-locked {
    width: 32px;
    height: 32px;
    position: absolute;
    /* right: 0; */
    margin-left: 55px;
    margin-top: -10px;
  }
  
  
  
  
  
  
  
  /*----------------------------------------------- BUBBLES (START) -----------------------------------------------*/
  /*CARD-FULL-BUBBLES*/ 
  #profile .card-full-bubbles {
    max-width: calc(100% - 20px);
    box-sizing: content-box;
  }
  
  
  
  
  
  /*HEADER-OPTIONS*/
  #profile .card-full-bubbles .header-options {
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 961px) {
    #profile .card-full-bubbles .header-options {
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 960px) {
    #profile .card-full-bubbles .header-options {
      justify-content: center;
    }
  }
  
  /*----------HEADER-OPTIONS LEFT----------*/
  #profile .card-full-bubbles .header-options .header-left {
    display: flex;
  }
  @media screen and (min-width: 961px) {
    #profile .card-full-bubbles .header-options .header-left {
      width: 264px;
      justify-content: flex-start;
    }
  }
  @media screen and (max-width: 960px) {
    #profile .card-full-bubbles .header-options .header-left {
      width: 100%;
      margin-bottom: 10px;
      justify-content: center;
    }
  }
  #profile .card-full-bubbles .header-options .header-left img {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    cursor: pointer;
  }
  
  /*----------HEADER-OPTIONS CENTER----------*/
  /*UNCOMMENT OUT AND ADJUST ONCE ADD BUTTONS IN HEADER-CENTER ELEMENT*/
  /*#profile .card-full-bubbles .header-options .header-center {
    height: 32px;
    background-color: yellow;
  }
  @media screen and (min-width: 961px) {
    #profile .card-full-bubbles .header-options .header-center {
      width: 72px;
    }
  }
  @media screen and (max-width: 960px) {
    #profile .card-full-bubbles .header-options .header-center {
      width: 100%;
      margin-bottom: 10px;
    }
  }*/
  
  /*----------HEADER-OPTIONS RIGHT----------*/
  #profile .card-full-bubbles .header-options .header-right {
    width: 100%;
    display: flex;
  }
  @media screen and (min-width: 961px) {
    #profile .card-full-bubbles .header-options .header-right {
      max-width: 264px;
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: 960px) {
    #profile .card-full-bubbles .header-options .header-right {
      margin-bottom: 10px;
      justify-content: center;
    }
  }
  
  /*OVERRIDE MARGINS FROM MAIN.CSS*/
  #profile .card-full-bubbles .header-range-standard {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #profile .card-full-bubbles .header-range-custom {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  /*HEADER ELEMENTS TO HIDE ON LOAD*/
  #profile .card-full-bubbles .header-options .header-left .type-green img:last-of-type {
    display: none;
  }
  #profile .card-full-bubbles .header-options .header-left .type-red img:first-of-type {
    display: none;
  }
  #profile .card-full-bubbles .header-options .header-left .type-percent img:first-of-type {
    display: none;
  }
  
  
  
  
  
  /*BODY*/
  #profile .card-full-bubbles .body {
    display: flex;
    justify-content: center;
    box-sizing: content-box;
  }
  
  
  
  
  
  /*MATRIX-WRAPPER*/ 
  #profile .card-full-bubbles .body .matrix-wrapper {
    max-width: calc(100% - 16px);
    /*IF ADJUST THIS MARGIN, UPDATE MATRIXWRAPPER VARIABLE JS FILE*/
    margin-left: 8px;
    margin-right: 8px;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    overflow-x: scroll;
    border-radius: 40px;
    background-color: #FAFAFB;
    position: relative;
    display: inline;
    box-sizing: content-box;
  }
  
  
  
  /*MATRIX-ROW*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-right: 16px;
    box-sizing: content-box;
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  
  
  
  /*MATRIX CELLS*/
  /*MATRIX-CELL (DATE-PLACEHOLDER)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date-placeholder {
    width: 128px;
    height: 36px;
    display: flex;
    position: sticky;
    left: 0px;
    top: 0px;
    height: 40px;
    z-index: 2;
    box-sizing: content-box;
    background-color: #FAFAFB;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date-placeholder > div {
    width: 128px;
    box-sizing: content-box;
    background-color: #FAFAFB;
  }
  
  /*MATRIX-CELL (DATE)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date {
    box-sizing: content-box;
  }
  .matrix-cell-date-show {
    display: block;
    height: 40px;
    width: 24px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .matrix-cell-date-hide {
    display: none;
    width: 0px;
    height: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date .matrix-date {
    width: 20px;
    height: 40px;
    border-radius: 8px;
    box-sizing: content-box;
    background-color: #F5F5F8;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date .matrix-date:hover {
    width: 20px;
    background-color: #ECECF1;
  }
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date .matrix-date h6 {
    text-align: center;
    font-size: 8px;
    font-weight: 600;
    cursor: default;
    box-sizing: content-box;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date .matrix-date h6.matrix-date-month {
    width: 100%;
    padding-top: 5px;
    box-sizing: content-box;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date .matrix-date h6.matrix-date-day {
    width: 100%;
    margin-top: -3px;
    margin-bottom: -2px;
    box-sizing: content-box;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date .matrix-date h6[class*="matrix-date-week"] {
    width: 14px;
    height: 10px;
    border-radius: 5px;
    margin-left: 3px;
    display: inline-block;
    box-sizing: content-box;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date .matrix-date h6.matrix-date-weekday {
    box-sizing: content-box;
    background-color: #E6E6EC;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-date .matrix-date h6.matrix-date-weekend {
    box-sizing: content-box;
    background-color: white;
  }
  
  
  
  
  
  /*MATRIX-CELL (NAME)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-name {
    display: flex;
    position: sticky;
    left: 0px;
    top: 0px;
    padding-left: 20px;
    z-index: 2;
    box-sizing: content-box;
    background-color: #FAFAFB;
  }
  /*MATRIX-CELL (NAME - ONLY WEIGHT ROW)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row:nth-of-type(2) .matrix-cell-name {
    /* margin-right: -10px; */
    padding-top: 39px;
    padding-bottom: 38px;
    box-sizing: content-box;
  }
  /*MATRIX-CELL (NAME - ALL BUT WEIGHT ROW)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row:nth-child(n+3) .matrix-cell-name {
    padding-top: 6px;
    padding-bottom: 6px;
    box-sizing: content-box;
  }
  
  /*MATRIX-CELL (NAME - TOPIC)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-name .topic {
    width: 60px;
    font-weight: 600;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 4px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    cursor: default;
    letter-spacing: -.25px;
    box-sizing: content-box;
    background-color: #F5F5F8;
    word-break: break-all;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-name:hover .topic {
    background-color: #ECECF1;
  }
  
  /*MATRIX-CELL (NAME - ENGAGEMENT)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-name .engagement {
    width: 30px;
    font-size: 9px;
    font-weight: 400;
    padding-top: 6px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 2px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    cursor: default;
    box-sizing: content-box;
    background-color: #F5F5F8;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-name:hover .engagement {
    background-color: #ECECF1;
  }
  
  
  
  /*MATRIX-LINE*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"] .matrix-line {
    position: absolute;
    width: 2px;
    left: 7px;
    top: -10px;
    z-index: 0;
    box-sizing: content-box;
    background-color: #F3F3F5;
  }
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row:not(:last-child) [class*="matrix-cell"] .matrix-line {
    height: 36px;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row:last-child [class*="matrix-cell"] .matrix-line {
    height: 26px;
  }
  
  
  
  /*MATRIX-CELL (BUBBLE)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble {
    position: relative;
    overflow: visible;
    box-sizing: content-box;
  }
  .matrix-cell-bubble-show {
    display: block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .matrix-cell-bubble-hide {
    display: none;
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  
  
  /*MATRIX-CELL (BUBBLE DOT)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble .matrix-dot {
    width: 16px;
    height: 16px;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    border: 4px solid #FAFAFB;
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble .matrix-dot-bristol {
    width: 16px;
    height: 21px;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    border: 4px solid #FAFAFB;
    top:-2px;
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble .matrix-dot-bristol-multiple{
    width: 16px;
    height: 21px;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    border: 4px solid #FAFAFB;
    top: -8px;
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  /*MATRIX-CELL (BUBBLE DOT - RED)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.red .matrix-dot {
    background-color: #FC5834; 
  }
  /*MATRIX-CELL (BUBBLE DOT - YELLOW)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.yellow .matrix-dot {
    background-color: #FFC720;
  }
  /*MATRIX-CELL (BUBBLE DOT - GREEN)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.green .matrix-dot {
    background-color: #00CC91;
  }
  /*MATRIX-CELL (BUBBLE DOT - GREY)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.grey .matrix-dot {
    background-color: #F3F3F5;
  }
  /*MATRIX-CELL (BUBBLE DOT - hover)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble:hover .matrix-dot {
    border-color: #FAFAFB;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16)) drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.12));
  }
  
  /*MATRIX-CELL (BUBBLE DOT - Orange)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.orange .matrix-dot-bristol {
    background-color: #f5a623;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.green .matrix-dot-bristol{
    background-color: #00CC91;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.gray .matrix-dot-bristol{
    background-color: #696969;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.yellow .matrix-dot-bristol{
    background-color: #FFC720;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.red .matrix-dot-bristol{
    background-color: #FC5834;
  }
  /*MATRIX-CELL (BUBBLE DOT - Orange)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.orange .matrix-dot-bristol-multiple {
    background-color: #f5a623;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.green .matrix-dot-bristol-multiple{
    background-color: #00CC91;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.gray .matrix-dot-bristol-multiple{
    background-color: #696969;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.yellow .matrix-dot-bristol-multiple{
    background-color: #FFC720;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-bubble.red .matrix-dot-bristol-multiple{
    background-color: #FC5834;
  }
  
  .single-bubble{
    /* background-color: #00CC91; */
    height: 6px;
    width: 6px;
    border: 1px solid #ffffff;
    position: relative;
    top: 1px;
    left: 8px;
    z-index: 1;
    border-radius: 6px;
  }
  /*MATRIX-CELL (MILESTONE)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-milestone {
    position: relative;
    overflow: visible;
    box-sizing: content-box;
  }
  .matrix-cell-milestone-show {
    display: block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .matrix-cell-milestone-hide {
    display: none;
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  
  
  
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-milestone .matrix-milestone {
    width: 16px;
    height: 16px;
    position: relative;
    box-sizing: content-box;
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-milestone:hover .matrix-milestone {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16)) drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.12));
  }
  
  
  
  /*MATRIX-CELL (PHOTO)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo {
    position: relative;
    overflow: visible;
    box-sizing: content-box;
  }
  .matrix-cell-photo-show {
    display: block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .matrix-cell-photo-hide {
    display: none;
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .matrix-photo {
    width: 16px;
    height: 16px;
    position: relative;
    box-sizing: content-box;
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo:hover .matrix-photo {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16)) drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.12));
  }
  
  
  
  
  
  /*TOOLTIPS*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"] > .tooltip-standard {
    min-width: 140px;
    bottom: 20px;
    left: -76px;
    flex-wrap: wrap;
    white-space: normal;
    height: auto;
    max-height: 150px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: content-box;
    box-shadow: 0px 8px 16px -8px rgba(58,59,69,.1), 0px 8px 20px -8px rgba(58,59,69,.24);
  }
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"]:hover > .tooltip-standard {
    display: flex;
  }
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"] > .tooltip-standard:after,
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"] > .tooltip-standard:before {
    left: 72px;
  }
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"] > .tooltip-standard .tooltip-text {
    height: auto;
    overflow-y: scroll;
    max-height: 150px;
    width: 100%;
    box-sizing: content-box;
  }
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"] > .tooltip-standard .tooltip-text > div:not(:last-child) {
    width: 100%;
    margin-bottom: 8px;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"] > .tooltip-standard .tooltip-text h5 {
    width: 100%;
    font-weight: 600;
    margin-bottom: -3px;
    box-sizing: content-box;
  }
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row [class*="matrix-cell"] > .tooltip-standard .tooltip-text h6 {
    width: calc(100% - .5px);
    margin-left: .5px;
    font-weight: 400;
    box-sizing: content-box;
  }
  
  
  
  
  
  /*TOOLTIPS (PHOTO SPECIFIC)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo {
    position: relative;
    box-sizing: content-box;
  }
  
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo img {
    max-width: 140px;
    border-radius: 12px;
    display: block;
    box-sizing: content-box;
  }
  
  
  
  /*TOOLTIPS (PHOTO SPECIFIC) COMMENT-STATUS*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-comment-status {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 4px;
    left: 4px;
    box-sizing: content-box;
  }
  
  /*TOOLTIPS (PHOTO SPECIFIC) COMMENT-STATUS (IMG) */
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-comment-status img {
    width: 20px;
    height: 20px;
    box-sizing: content-box;
  }
  
  
  
  /*TOOLTIPS (PHOTO SPECIFIC) PHOTO-BUTTONS (WRAPPER)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-buttons-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    box-sizing: content-box;
  }
  
  /*TOOLTIPS (PHOTO SPECIFIC) PHOTO-BUTTONS (BUTTONS)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-buttons-wrapper .matrix-photo-buttons {
    padding: 4px;
    padding-right: 0px;
    padding-bottom: 4px;
    padding-left: 4px;
    border-radius: 8px;
    margin-bottom: 4px;
    margin-right: 4px;
    background-color: rgb(255,255,255,.7);
    z-index: 2;
    cursor: default;
    pointer-events: auto;
    align-self: flex-end;
    display: flex;
    box-sizing: content-box;
  }
  
  /*TOOLTIPS (PHOTO SPECIFIC) PHOTO-BUTTONS (BUTTON)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-buttons-wrapper .matrix-photo-buttons .matrix-photo-button {
    display: flex;
    box-sizing: content-box;
  }
  
  /*TOOLTIPS (PHOTO SPECIFIC) PHOTO-BUTTONS (BUTTON IMG)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-buttons-wrapper .matrix-photo-buttons .matrix-photo-button img {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: block;
    margin-right: 5px;
    cursor: pointer;
    box-sizing: content-box;
    background-color: rgba(255,255,255,.5);
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out;
  }
  /*(HOVER)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-buttons-wrapper .matrix-photo-buttons .matrix-photo-button img:hover {
    background-color: rgba(76,132,255,.2);
    box-shadow: 0px 0px 0px 2px #4C84FF;
  }
  
  
  
  /*TOOLTIPS (PHOTO SPECIFIC) PHOTO-BUTTONS (EXPLANATION)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-buttons-wrapper .matrix-photo-buttons .matrix-photo-button .matrix-photo-button-explanation {  
    display: none;
    position: absolute;
    height: 32px;
    z-index: 2;
    top: 0px;
    right: 0px;
    left: 0px;
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 12px;
    align-items: center;
    white-space: nowrap;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: content-box;
    box-shadow: 0px 8px 16px -8px rgba(58,59,69,.2), 0px 8px 20px -8px rgba(58,59,69,.48);
    background-color: rgb(255,255,255,.85);
  }
  /*(HOVER)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-buttons-wrapper .matrix-photo-buttons .matrix-photo-button:hover .matrix-photo-button-explanation {
    display: flex;
  }
  /*(H6)*/
  #profile .card-full-bubbles .body .matrix-wrapper .matrix-row .matrix-cell-photo .tooltip-standard .tooltip-photo .matrix-photo-buttons-wrapper .matrix-photo-buttons .matrix-photo-button .matrix-photo-button-explanation h6 {
    width: 100%;
    font-weight: 600;
    text-align: center;
    box-sizing: content-box;
  }
  
  
  
  
  
  /*CHART*/
  .weights-chart-container {
    display: flex;
    height: 100px;
    padding-left: 2px;
    margin-left: -10px;
    padding-right: 12px;
    overflow: visible;
    /*POSITION RELATIVE SHOULD ALWAYS BE A PART OF THE CHART'S PARENT!*/
    position: relative;
    box-sizing: content-box;
  }
  
  
  
  
  
  /*ADD IN JS*/
  .matrix-shadow {
    filter : drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16)) drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.12));
  }
  /*----------------------------------------------- BUBBLES (END) -----------------------------------------------*/
  
  .nd-score{
    color: #4C84FF;
    font-size: 15px;
    font-weight: bold;
    margin-left: 4px;
  }
  
  .add-weight-goal{
    cursor: pointer;
      width: 100px;
      height: 30px;
  }