/*----------------------------------------------- GENERAL -----------------------------------------------*/
html {
    margin: 0px;
    padding: 0px;
  }
  
  body {
    width: 100%;
    min-height: 100vh;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    background-color: #f8f9fc;
    margin: 0px;
    position: relative;
  }
  /*----------------------------------------------- GENERAL (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- PLACEHOLDER NAV -----------------------------------------------*/
  /*THIS IS PLACEHOLDER STYLING FOR FRONT-END CODING*/
  #placeholder-nav {
    height: 100vh;
    margin: 0px;
    padding: 0px;
    background-color: #4D85FF;
  }
  @media screen and (max-width: 768px) {
    #placeholder-nav {
      width: 104px;
    }
  }
  @media screen and (min-width: 769px) {
    #placeholder-nav {
      width: 224px;
    }
  }
  
  
  
  /*THIS IS PLACEHOLDER STYLING FOR FRONT-END CODING*/
  #placeholder-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  @media screen and (max-width: 768px) {
    #placeholder-wrapper {
      max-width: calc(100% - 104px);
    }
  }
  @media screen and (min-width: 769px) {
    #placeholder-wrapper {
      max-width: calc(100% - 224px);
    }
  }
  /*----------------------------------------------- PLACEHOLDER NAV (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- FONTS -----------------------------------------------*/
  h1, h2, h3,h4, h5, h6 {
    font-family: 'Source Sans Pro', sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    margin: 0px;
    color: #858796;
  }
  
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 36px;
      letter-spacing: -1.5px;
    }
  
    h2 {
      font-size: 30px;
      letter-spacing: -1px;
    }
  
    h3 {
      font-size: 21px;
      letter-spacing: -.75px;
    }
  }
  
  @media screen and (min-width: 769px) {
    h1 {
      font-size: 40px;
      letter-spacing: -2px;
    }
  
    h2 {
      font-size: 32px;
      letter-spacing: -1.5px;
    }
  
    h3 {
      font-size: 24px;
      letter-spacing: -1px;
    }
  }
  
  /*ALL MEDIA QUERIES*/
  h4 {
    font-size: 18px;
    letter-spacing: -.5px;
  }
  
  h5 {
    font-size: 15px;
    letter-spacing: -.25px;
  }
  h6 {
    font-size: 12px;
    letter-spacing: 0px;
  }
  /*----------------------------------------------- FONTS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- BUTTONS -----------------------------------------------*/
  button:focus {outline:0;}
  
  button:hover {
    cursor: pointer;
  }
  /*----------------------------------------------- BUTTONS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- CARDS -----------------------------------------------*/
  /*CARD*/
  [class*="card-standard"] {
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
    background-color: white;
    position: relative;
  }
  
  #connect-dots [class*="card-standard"]:hover {
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  
  
  /*CARD-FULL*/
  #connect-dots [class*="card-standard-full"] {
    width: 100%;
  }
  
  
  
  /*CARD-THIRD*/
  #connect-dots [class*="card-standard-third"] {
    height: 340px;
    overflow: hidden;
  }
  @media screen and (min-width: 1144px) {
    #connect-dots [class*="card-standard-third"] {
      width: 100%;
      max-width: 280px;
    }
  }
  @media screen and (min-width: 844px) and (max-width: 1143px) {
    #connect-dots [class*="card-standard-third"] {
      width: calc(50% - 20px);
    }
  }
  @media screen and (max-width: 843px) {
    #connect-dots [class*="card-standard-third"] {
      width: calc(100% - 20px);
    }
  }
  
  
  
  /*CARD-HEADER*/
  #connect-dots [class*="card-standard"] .header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #F5F6F8;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  
  #connect-dots [class*="card-standard"] .header h4 {
    width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 12px;
    text-align: center;
  }
  
  
  
  /*CARD-THIRD-BODY*/
  #connect-dots [class*="card-standard-third"] .body {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(100% - 24px);
    padding-right: 12px;
    padding-left: 12px;
    height: 260px;
    margin-top: 10px;
    overflow: scroll;
  }
  
  
  
  /*MODULE*/
  #connect-dots [class*="card-standard-third"] .body .module {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #connect-dots [class*="card-standard-third"] .body .module:not(:last-child) {
    border-bottom: 2px solid #f8f9fc;
  }
  
  
  
  /*MODULE-TOP*/
  #connect-dots [class*="card-standard-third"] .body .module .module-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  #connect-dots [class*="card-standard-third"] .body .module .module-top .module-icon {
    width: 32px;
    height: 32px;
  }
  
  #connect-dots [class*="card-standard-third"] .body .module .module-top div {
    width: calc(100% - 60px);
    margin-right: 6px;
    margin-left: 8px;
  }
  
  #connect-dots [class*="card-standard-third"] .body .module .module-top div h5 {
    width: 100%;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #connect-dots [class*="card-standard-third"] .body .module .module-top div h5:hover {
    overflow: visible;
  }
  
  #connect-dots [class*="card-standard-third"] .body .module .module-top div h6 {
    width: 100%;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  #connect-dots [class*="card-standard-third"] .body .module .module-top div h6:hover {
    overflow: visible;
  }
  
  #connect-dots [class*="card-standard-third"] .body .module .module-top [class*="module-status"] {
    width: 20px;
    height: 20px;
  }
  #connect-dots [class*="card-standard-third"] .body .module .module-top .module-status-edit {
    border-radius: 10px;
  }
  #connect-dots [class*="card-standard-third"] .body .module .module-top .module-status-edit:hover {
    background-color: #E5EDFF;
    cursor: pointer;
  }
  
  
  
  /*MODULE-BOTTOM*/
  #connect-dots [class*="card-standard-third"] .body .module .module-bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
  }
  
  #connect-dots [class*="card-standard-third"] .body .module .module-bottom h6 {
    width: 100%;
    padding-left: 40px;
  }
  
  
  
  /*ADD*/
  #connect-dots [class*="card-standard-third"] .body .add {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    bottom: 4px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #E4EDFF;
  }
  #connect-dots [class*="card-standard-third"] .body .add:hover {
    background-color: #C9DAFF;
  }
  /*----------------------------------------------- CARDS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- INSUFFICIENT -----------------------------------------------*/
  .insufficient {
    display: none;
    width: 100%;
    max-width: 220px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .insufficient h5 {
    width: 100%;
    font-weight: 600;
    text-align: center;
  }
  
  .insufficient h6 {
    width: 100%;
    text-align: center;
  }
  /*----------------------------------------------- INSUFFICIENT -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- DROPDOWN SINGLE -----------------------------------------------*/
  /*DROPDOWN SINGLE*/
  .dropdown-single {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 40px;
    margin-bottom: 8px;
  }
  
  
  
  /*DROPDOWN - TOP*/
  .dropdown-single .dropdown-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    height: 38px;
    border-radius: 4px;
    border: 1px solid #D8D8D8;
  }
  .dropdown-single .dropdown-top:hover {
    cursor: pointer;
    border: 1px solid #4D85FF !important;
    background-color: #E8F0FF !important; 
  }
  .dropdown-single .dropdown-item:hover {
    cursor: pointer;
  }
  
  .dropdown-single .dropdown-top h6 {
    color: #858796;
    opacity: .5;
  }
  
  .dropdown-single .dropdown-top-add {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  
  
  
  /*DROPDOWN - CARROT/ARROW*/
  .dropdown-single .dropdown-top div {
    position: relative;
    height: 29px;
    width: 9px;
    margin-right: 20px;
  }
  
  .dropdown-single .dropdown-top span {
    position: absolute; 
    width: 2px;
    height: 6px;
    margin: auto;
    border-radius: 1px;
    background-color: #858796;
    -webkit-transition:all .25s ease-in-out;
       -moz-transition:all .25s ease-in-out;
        -ms-transition:all .25s ease-in-out;
         -o-transition:all .25s ease-in-out;
            transition:all .25s ease-in-out; 
  }
  
  .dropdown-single .dropdown-top:hover span {
    background-color: #4D85FF;
  }
  
  .dropdown-single .dropdown-top span:first-of-type {
    bottom: 10px;
    left: 2px; 
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .dropdown-single .dropdown-single:hover span:first-of-type {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  .dropdown-single .dropdown-top span:last-of-type {
    bottom: 10px;
    left: 6px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);  
  }
  
  .dropdown-single .dropdown-single:hover span:last-of-type {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  
  
  /*DROPDOWN-ITEMS*/
  .dropdown-single .dropdown-items {
    width: 100%;
    height: 0px;
    overflow-y: scroll;
    z-index: 2;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  
  /*DROPDOWN-ITEMS (ADD IN JS)*/
  .dropdown-single .dropdown-items-add {
    height: 81px !important;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
  }
  
  
  /*DROPDOWN-ITEM*/
  .dropdown-single .dropdown-item {
    width: 100%;
    align-items: center;
    align-content: center;
    background-color: white;
    margin-top: -1px;
  }
  
  .dropdown-single .dropdown-item:hover {
    background-color: #E8F0FF;
  }
  
  .dropdown-single .dropdown-item:hover h6 {
    color: #4D85FF;
  }
  
  .dropdown-single .dropdown-item h6 {
    text-align: left; 
    padding-left: 24px;
    padding-top: 9px;
    padding-bottom: 10px;
  }
  /*----------------------------------------------- DROPDOWN SINGLE (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- INPUT-TEXT AND TEXTAREA-TEXT -----------------------------------------------*/
  /*INPUT BOXES*/
  .input-text,
  .textarea-text {
    font-family: 'Source Sans Pro', sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    margin: 0px;
    color: #858796;
    font-size: 15px;
    padding-left: 20px;
    width: calc(100% - 20px);
    border: 0px;
    border-radius: 8px;
    background-color: #F3F4F5;
  }
  
  .input-text {
    height: 40px;
  }
  
  .textarea-text {
    padding-top: 12px;
    resize: none;
  }
  
  .input-text:focus,
  .input-text:hover,
  .textarea-text:focus,
  .textarea-text:hover {
    background-color: #F8F9FC;
    box-shadow: 0px 0px 0px 2px #F3F4F5 inset;
    outline: none;
    -webkit-transition:all .25s ease-in-out;
     -moz-transition:all .25s ease-in-out;
      -ms-transition:all .25s ease-in-out;
       -o-transition:all .25s ease-in-out;
          transition:all .25s ease-in-out;
  }
  /*----------------------------------------------- INPUT-TEXT AND TEXTAREA-TEXT (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- RADIO -----------------------------------------------*/
  /*RADIOS*/
  .radios {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  /* RADIOS LABEL */
  .radios label {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* HIDE DEFAULT RADIO */
  .radios label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* NEW RADIO */
  .radios label .dot {
    position: absolute;
    height: 19px;
    width: 19px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* BLUE BACKGROUND WHEN CHECKED */
  .radios label input:checked ~ .dot {
    background-color: #4E85FF;
  }
  
  /* HIDE DOT */
  .radios label .dot:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* SHOW DOT WHEN CHECKED */
  .radios label input:checked ~ .dot:after {
    display: block;
  }
  
  /* DOT STYLING */
  .radios label .dot:after {
    top: 7px;
    left: 7px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
  }
  
  /*RADIO LABEL H5 OR H6*/
  .radios label h5,
  .radios label h6 {
    padding-left: 24px;
  }
  
  
  .radios label:hover .dot {
    background-color: #F8F9FC;
    box-shadow: 0px 0px 0px 2px #F3F4F5 inset;
  }
  /*----------------------------------------------- RADIO (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- CHECKBOX -----------------------------------------------*/
  .checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 12px;
  }
  @media screen and (max-width: 768px) {
    .checkbox-container {
      width: 100%;
    }
  }
  
  .checkbox-container input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkbox-container span {
    position: absolute;
    border: 1px solid #D8D8D8;
    height: 19px;
    width: 19px;
    border-radius: 50%;
  }
  
  .checkbox-container:hover span {
    background-color: white;
    box-shadow: 0px 0px 0px 2px #F3F4F5 inset;
  }
  
  .checkbox-container input[type="checkbox"]:checked ~ span {
    border-color: #4E85FF;
    background-color: #4E85FF;
  }
  
  .checkbox-container span:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-container input[type="checkbox"]:checked ~ span:after {
    display: block;
  }
  
  .checkbox-container span:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .checkbox-container span:after {
    width: 3px;
    height: 7px;
    left: 7px;
    top: 4px;
    border: solid white;
    border-width: 0 2px 2px 0;
  }
  
  .checkbox-container h5,
  .checkbox-container h6 {
    padding-left: 28px;
  }
  /*----------------------------------------------- CHECKBOX (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- PROMPTS -----------------------------------------------*/
  /*PROMPTS*/
  #prompts {
    width: 100%;
    height: 100%;
    left: 0px;
    background-color: rgba(44,56,58,.8);
    position: fixed;
    display: none;
    z-index: 3;
  }
  
  
  
  /*PROMPT*/
  #prompts [class*="prompt"] {
    width: calc(100% - 40px);
    max-width: 540px;
    max-height: calc(100vh - 80px);
    border-radius: 20px;
    margin: auto;
    z-index: 10;
    background-color: white;
    position: relative;
    display: none;
    justify-content: center;
    box-shadow: 0px 20px 20px -10px rgba(0,0,0,.2), 0px 10px 40px -10px rgba(0,0,0,.5);
  }
  
  /*PROMPT CONTAINER*/
  #prompts [class*="prompt"] .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 220px;
    margin-top: 80px;
    margin-bottom: 40px;
    overflow: auto;
    width: 100%;
    padding-left: calc((100% - 220px)/2);
    padding-right: calc((100% - 220px)/2);
  }
  
  /*PROMPT HEADER*/
  #prompts [class*="prompt"] .container .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 16px;
  }
  
  /*PROMPT HEADER IMG*/
  #prompts [class*="prompt"] .container .header img {
    width: 40px;
    height: 40px;
  }
  
  /*PROMPT HEADER H3*/
  #prompts [class*="prompt"] .container .header h3 {
    font-weight: 600;
    margin-left: 4px;
  }
  
  
  
  /*PROMPT DESCRIPTION-TEXT-BEFORE*/
  #prompts [class*="prompt"] .container .description-text-before {
    margin-bottom: 24px;
  }
  
  /*PROMPT DESCRIPTION-TEXT-AFTER*/
  #prompts [class*="prompt"] .container .description-text-after {
    width: 100%;
    text-align: right;
  }
  
  
  
  /*PROMPT FORM/INPUT-TEXT*/
  #prompts [class*="prompt"] form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  
  #prompts .input-text {
    margin-bottom: 8px;
  }
  
  #prompts [class*="prompt"] form h6 {
    position: absolute;
    bottom: -7px;
    right: 0px;
    margin: 0px;
    color: #F5A623;
    z-index: 20;
  }
  
  
  
  /*PROMPT RADIOS*/
  #prompts .radios label {
    width: 100%;
    margin-bottom: 8px;
  }
  
  
  
  /*PROMPT BUTTON*/
  #prompts [class*="prompt"] button {
    width: 100%;
    height: 42px;
    border-radius: 4px;
    background-color: #4E85FF;
    border: 0px;
    margin-top: 32px;
  }
  
  #prompts [class*="prompt"] button a {
    text-decoration: none;
  }
  
  #prompts [class*="prompt"] button h5 {
    color: white;
    font-weight: 600;
    line-height: 42px;
  }
  
  
  
  /*PROMPT CLOSE*/
  #prompts [class*="prompt"] .close {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 1;
  }
  
  #prompts [class*="prompt"] .close:hover {
    background-color: #E8F0FF !important;
    cursor: pointer;
  }
  /*----------------------------------------------- PROMPTS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- TOOTLTIPS -----------------------------------------------*/
  /*TOOLTIP-STANDARD*/
  .tooltip-standard h6 {
    font-weight: 600;
  }
  
  .tooltip-standard {
    display: none;
    position: absolute;
    background-color: white;
    height: 40px;
    z-index: 2;
    border-radius: 20px;
    align-items: center;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  .tooltip-standard:after, .tooltip-standard:before {
    position: absolute;
    width: 0px; 
    height: 0px;
    bottom: -12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid white;
    content: " ";
  }
  
  
  
  /*TOOLTIP-INVERSE*/
  .tooltip-inverse h6 {
    font-weight: 600;
  }
  
  .tooltip-inverse {
    display: none;
    position: absolute;
    background-color: white;
    height: 40px;
    z-index: 2;
    border-radius: 20px;
    align-items: center;
    white-space: nowrap;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px -10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
  }
  
  .tooltip-inverse:after, .tooltip-inverse:before {
    position: absolute;
    width: 0px; 
    height: 0px;
    top: -12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
    content: " ";
  }
  /*----------------------------------------------- TOOTLTIPS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- RANGE BUTTONS -----------------------------------------------*/
  /*HEADER-RANGE*/
  .header-range {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  /*HEADER-RANGE-STANDARD*/
  .header-range-standard {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    cursor: pointer;
  }
  @media screen and (min-width: 401px) and (max-width: 960px) {
    .header-range-standard {
      width: 100%;
    }
  }
  @media screen and (max-width: 400px) {
    .header-range-standard {
      width: 108px;
      flex-wrap: wrap;
    }
  }
  
  /*HEADER-RANGE-STANDARD BUTTON*/
  .header-range-standard [class*="range-button"] {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    position: relative;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  @media screen and (min-width: 401px) {
    .header-range-standard [class*="range-button"] {
      width: 60px;
    }
    .header-range-standard [class*="range-button"]:not(:last-child) {
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 400px) {
    .header-range-standard [class*="range-button"] {
      width: 108px;
      margin-bottom: 8px;
    }
  }
  
  .header-range-standard [class*="range-button"] > h6 {
    font-weight: 600;
  }
  
  /*STANDARD - ADDED IN JS*/
  .range-button-standard-active {
    background-color: #4C84FF;
  }
  .range-button-standard-inactive {
    background-color: white;
  }
  .range-button-standard-active > h6 {
    color: white;
  }
  .range-button-standard-inactive > h6 {
    color: #4C84FF;
  }
  
  
  
  /*HEADER-RANGE-CUSTOM*/
  .header-range-custom {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    cursor: pointer;
    position: relative;
  }
  @media screen and (min-width: 401px) and (max-width: 960px) {
    .header-range-custom {
      width: 100%;
    }
  }
  @media screen and (max-width: 400px) {
    .header-range-custom {
      width: 108px;
      flex-wrap: wrap;
    }
  }
  
  /*HEADER-RANGE-CUSTOM BUTTON*/
  .header-range-custom [class*="range-button"] {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  @media screen and (min-width: 401px) {
    .header-range-custom [class*="range-button"] {
      width: 60px;
    }
    .header-range-custom [class*="range-button"]:not(:last-child) {
      margin-right: 8px;
    }
  }
  @media screen and (max-width: 400px) {
    .header-range-custom [class*="range-button"] {
      width: 108px;
      margin-bottom: 8px;
    }
  }
  
  .header-range-custom [class*="range-button"] > h6 {
    font-weight: 600;
  }
  
  /*HEADER-RANGE-CUSTOM INPUT*/
  .header-range-custom input {
    background: none;
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  
    font-family: 'Source Sans Pro', sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    margin: 0px;
    color: #4C84FF;
    font-size: 12px;
    letter-spacing: 0px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
  }
  
  .header-range-custom input {
    outline: 0;
  }
  
  .range-button-reset {
    background-color: #F5A623;
  }
  
  .range-button-reset > h6 {
    color: white;
  }
  
  #range-button-submit {
    background-color: #4C84FF;
  }
  
  #range-button-submit > h6 {
    color: white;
  }
  
  .range-button-submit {
    background-color: #4C84FF;
  }
  
  .range-button-submit > h6 {
    color: white;
  }
  /*----------------------------------------------- RANGE BUTTONS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- DATEPICKER -----------------------------------------------*/
  .ui-datepicker {
    width: 256px;
    border-radius: 20px;
    background-color: white;
    overflow: hidden;
    z-index: 3 !important;
    font-family: 'Source Sans Pro', sans-serif;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    margin: 0px;
    font-size: 12px;
    letter-spacing: 0px;
    font-weight: 400;
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.2), 0px 10px 40px -10px rgba(58,59,69,.48);
    /*DAYS OF WEEK COLOR*/
    color: #C0BFCE;
  }
  
  /*HEADER*/
  .ui-datepicker-header {
    height: 40px;
    line-height: 40px;
    font-weight: 600;
    color: #82819E;
  }
  
  /*ARROWS*/
  .ui-datepicker-prev,
  .ui-datepicker-next {
    width: 32px;
    height: 32px;
    text-indent: 9999px;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
  }
  
  /*ARROW-PREV*/
  .ui-datepicker-prev {
    float: left;
    margin-top: 4px;
    margin-left: 4px;
  }
  .ui-datepicker-prev:after {
    transform: rotate(45deg);
    margin: -27px 0px 0px 14px;
  }
  
  /*ARROW-NEXT*/
  .ui-datepicker-next {
    float: right;
    margin-top: 4px;
    margin-right: 4px;
  }
  .ui-datepicker-next:after {
    transform: rotate(-135deg);
    margin: -27px 0px 0px 12px;
  }
  .ui-datepicker-prev:after,
  .ui-datepicker-next:after {
    content: '';
    position: absolute;
    display: block;
    width: 4px;
    height: 4px;
    border-left: 2px solid #4C84FF;
    border-bottom: 2px solid #4C84FF;
  }
  
  /*ARROWS-HOVER*/
  .ui-datepicker-prev:hover,
  .ui-datepicker-next:hover,
  .ui-datepicker-prev:hover:after,
  .ui-datepicker-next:hover:after {
    border-color: #4C84FF;
  }
  .ui-datepicker-prev:hover,
  .ui-datepicker-next:hover {
    background-color: #E4EDFF;
  }
  
  /*TITLE*/
  .ui-datepicker-title {
    text-align: center;
    font-size: 15px;
  }
  
  /*CALENDAR*/
  .ui-datepicker-calendar {
    width: 100%;
    text-align: center;
  }
  
  /*HEAD/ROW/CELL/SPAN*/
  .ui-datepicker-calendar thead tr th span {
    display: block;
    width: 32px;
    margin-bottom: 5px;
  }
  
  /*CELL-DEFAULT*/
  .ui-state-default {
    display: block;
    text-decoration: none;
    color: #82819E;
    line-height: 32px;
    border-radius: 50%;
  }
  .ui-state-default:hover {
    color: #4C84FF;
    background: #E4EDFF;
  }
  
  /*CELL-HIGHLIGHT*/
  .ui-state-highlight {
    font-weight: 600;
    color: #4C84FF;
  }
  
  /*CELL-ACTIVE*/
  .ui-state-active {
    font-weight: 600;
    color: white;
    background-color: #4C84FF;
  }
  .ui-state-active:hover {
    color: white;
    background-color: #4C84FF;
    box-shadow: 0px 0px 0px 3px #245CD7 inset;
  } 
  /*----------------------------------------------- DATEPICKER (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- TOGGLER BUTTON -----------------------------------------------*/
  .toggler-button {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  .toggler-button h5 {
    color: #4C84FF;
    text-align: center;
    line-height: 12px;
    margin-bottom: -2px;
    font-weight: 600;
  }
  
  .toggler-button-active {
    background-color: #E8F0FF;
    border: 2px solid #4C84FF;
  }
  .toggler-button-active:hover {
    border: 2px solid #4C84FF;
  }
  
  .toggler-button-inactive {
    background-color: white;
    border: 2px solid white;
  }
  .toggler-button-inactive:hover {
    border: 2px solid #E8F0FF;
  }
  /*----------------------------------------------- TOGGLER BUTTON (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- HEURISTIC BUTTONS -----------------------------------------------*/
  .heuristic-button-green, .heuristic-button-yellow, .heuristic-button-red, .heuristic-button-grey {
    width: 86px;
    height: 36px;
    border-radius: 20px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
  }
  
  
  
  /*HEURISTIC-BUTTON GREEN*/
  .heuristic-button-green:hover {
    border: 2px solid rgb(0,204,145,.1);
  }
  
  .heuristic-button-green-active {
    background-color: #E5F9F4;
    border: 2px solid #00CC91;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,.1), 0px 2px 4px 0px rgba(0,0,0,.2);
  }
  
  .heuristic-button-green-active:hover {
    border: 2px solid #00CC91;
  }
  
  .heuristic-button-green-active h5 {
    font-weight: 700;
    letter-spacing: -.5px;
    color: #00CC91;
  }
  
  .heuristic-button-green-inactive {
    background-color: white;
    border: 2px solid white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  .heuristic-button-green-inactive h5 {
    font-weight: 600;
    letter-spacing: -.5px;
    color: #00CC91;
  }
  
  
  
  /*HEURISTIC-BUTTON YELLOW*/
  .heuristic-button-yellow:hover {
    border: 2px solid rgb(255,199,32,.1);
  }
  
  .heuristic-button-yellow-active {
    background-color: #FFF9E8;
    border: 2px solid #FFC720;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,.1), 0px 2px 4px 0px rgba(0,0,0,.2);
  }
  
  .heuristic-button-yellow-active:hover {
    border: 2px solid #FFC720;
  }
  
  .heuristic-button-yellow-active h5 {
    font-weight: 700;
    letter-spacing: -.5px;
    color: #FFC720;
  }
  
  .heuristic-button-yellow-inactive {
    background-color: white;
    border: 2px solid white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  .heuristic-button-yellow-inactive h5 {
    font-weight: 600;
    letter-spacing: -.5px;
    color: #FFC720;
  }
  
  
  
  /*HEURISTIC-BUTTON RED*/
  .heuristic-button-red:hover {
    border: 2px solid rgb(252,88,52,.1);
  }
  
  .heuristic-button-red-active {
    background-color: #FEEEEA;
    border: 2px solid #FC5834;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,.1), 0px 2px 4px 0px rgba(0,0,0,.2);
  }
  
  .heuristic-button-red-active:hover {
    border: 2px solid #FC5834;
  }
  
  .heuristic-button-red-active h5 {
    font-weight: 700;
    letter-spacing: -.5px;
    color: #FC5834;
  }
  
  .heuristic-button-red-inactive {
    background-color: white;
    border: 2px solid white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  .heuristic-button-red-inactive h5 {
    font-weight: 600;
    letter-spacing: -.5px;
    color: #FC5834;
  }
  
  /*HEURISTIC-BUTTON GREY*/
  .heuristic-button-grey:hover {
    border: 2px solid rgb(184,184,184,.1);
  }
  
  .heuristic-button-grey-active {
    background-color: #ececec;
    border: 2px solid #B8B8B8;
    box-shadow: 0px 10px 20px 0px rgba(0,0,0,.1), 0px 2px 4px 0px rgba(0,0,0,.2);
  }
  
  .heuristic-button-grey-active:hover {
    border: 2px solid #B8B8B8;
  }
  
  .heuristic-button-grey-active h5 {
    font-weight: 700;
    letter-spacing: -.5px;
    color: #B8B8B8;
  }
  
  .heuristic-button-grey-inactive {
    background-color: white;
    border: 2px solid white;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  .heuristic-button-grey-inactive h5 {
    font-weight: 600;
    letter-spacing: -.5px;
    color: #B8B8B8;
  }
  /*----------------------------------------------- HEURISTIC BUTTONS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- RESULTS-COUNT (END) -----------------------------------------------*/
  .results-count {
    display: none;
    min-width: 12px;
    height: 20px;
    position: absolute;
    top: -8px;
    right: -8px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 10px;
    background-color: #F5F6F8;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,.12), 0px -1px 2px 0px rgba(0,0,0,.12);
  }
  
  .results-count h6 {
    min-width: 12px;
    margin-top: 2px;
    color: #858796;
    font-weight: 600;
    text-align: center;
  }
  /*----------------------------------------------- RESULTS-COUNT (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- NAV-BUTTONS -----------------------------------------------*/
  /*BUTTONS*/
  .nav-buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  
  
  /*BUTTON*/
  .nav-buttons .nav-button {
    margin-left: 4px;
    margin-right: 4px;
    display: flex;
    height: 40px;
    align-items: center;
    text-decoration: none;
    position: relative;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,.05), 0px 1px 2px 0px rgba(0,0,0,.12);
  }
  
  /*BUTTON H5*/
  .nav-buttons .nav-button h5 {
    font-weight: 600;
    color: #4C84FF;
    padding-left: 12px;
    padding-right: 4px;
    display: none;
  }
  
  /*BUTTON IMG*/
  .nav-buttons .nav-button img {
    height: 32px;
    width: 32px;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 50%;
    display: block;
    background-color: white;
  }
  
  /*BADGE*/
  .nav-buttons .nav-button .badge {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    background-color: #F5A623;
  }
  
  /*BADGE H6*/
  .nav-buttons .nav-button .badge h6 {
    font-weight: 700;
    color: white;
    letter-spacing: -.5px;
  }
  
  
  
  /*ACTIVE BUTTON*/
  .nav-buttons .active-nav-button {
    background-color: #4C84FF;
  }
  
  .nav-buttons .active-nav-button h5 {
    display: block;
    color: white;
  }
  
  .nav-buttons .active-nav-button img {
    background-color: #F2F6FF;
  }
  
  
  
  /*INACTIVE BUTTON*/
  .nav-buttons .inactive-nav-button:hover {
    box-shadow: 0px 0px 0px 2px #4C84FF inset;
  }
  
  .nav-buttons .inactive-nav-button:hover img {
    background-color: #F2F6FF;
    box-shadow: 0px 0px 0px 2px white inset;
  }
  
  .nav-buttons .inactive-nav-button:hover .tooltip {
    display: flex;
  }
  
  
  
  /*TOOLTIP*/
  .nav-buttons .nav-button .tooltip h6 {
    width: 100%;
    font-weight: 600;
    text-align: center;
    color: #4C84FF;
  }
  
  .nav-buttons .nav-button .tooltip {
    display: none;
    position: absolute;
    top: -40px;
    left: -20px;
    margin: auto;
    width: 60px;
    background-color: white;
    height: 40px;
    border-radius: 20px;
    align-items: center;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: 0px 10px 20px -10px rgba(58,59,69,.1), 0px 10px 40px -10px rgba(58,59,69,.24); 
    z-index: 2;
  }
  
  .nav-buttons .inactive-nav-button .tooltip:after, .nav-buttons .inactive-nav-button .tooltip:before {
    position: absolute;
    width: 0px; 
    height: 0px;
    bottom: -12px;
    right: 0px;
    left: 0px;
    margin: auto;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid white;
    content: " ";
  }
  /*----------------------------------------------- NAV-BUTTONS (END) -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- BUTTON CTA STANDARD  -----------------------------------------------*/
  .button-cta-standard {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #4E85FF;
    border: 0px;
    cursor: pointer;
  }
  
  /*CAN USE A-LINK, BUT DONT NEED TO*/
  .button-cta-standard a {
    width: 100%;
    height: 100%;
    text-decoration: none;
  }
  
  .button-cta-standard h5 {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 600;
    line-height: 42px;
  }
  
  
  
  .button-cta-standard-secondary {
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: white;
    border: 0px;
    cursor: pointer;
  }
  .button-cta-standard-secondary:hover {
    background-color: #E5EDFF;
  }
  
  .button-cta-standard-secondary a {
    width: 100%;
    height: 100%;
    text-decoration: none; 
  }
  
  .button-cta-standard-secondary h5 {
    width: 100%;
    text-align: center;
    color: #4E85FF;
    font-weight: 600;
    line-height: 42px;
  }
  
  
  
  /*CREATE SHAKE ANIMATION*/
  .shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  /*----------------------------------------------- BUTTON CTA STANDARD (END)  -----------------------------------------------*/
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*----------------------------------------------- TABLE -----------------------------------------------*/
  .table-standard {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    position: relative;
  }
  
  .table-standard tr {
    -webkit-transition:all .1s ease-in-out;
       -moz-transition:all .1s ease-in-out;
        -ms-transition:all .1s ease-in-out;
         -o-transition:all .1s ease-in-out;
            transition:all .1s ease-in-out;
  }
  .table-standard tr:hover:not(:first-child) {
    background-color: #F8F9FC;  
  }
  
  .table-standard th {
    text-align: center;
    padding-bottom: 10px;
    border: none;
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 2;
  }
  
  .table-standard th h5 {
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /*TD*/
  .table-standard td {
    text-align: center;
    height: 24px;
  }
  
  /*TD H6*/
  .table-standard tr td h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /*----------------------------------------------- TABLE (END) -----------------------------------------------*/
  
  
  
  
  /*----------------------------------------------- DROP IMAGE -----------------------------------------------*/
  .drop-img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 20px 0 12px;
    background-color: rgba(90, 134, 248, 0.07);
    border-radius: 32px;
    padding-bottom: 10px;
  }
  
  .drop-img {
    width: 60px;
    height: 60px;
    padding-top: 18px;
    padding-bottom: 8px;
  }
  
  .drop-img-container h5 {
    font-weight: 600;
  }
  /*----------------------------------------------- DROP IMAGE (END) -----------------------------------------------*/